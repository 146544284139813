import React from 'react';

import BlueprintLayout from '../components/ui/Layout/BlueprintLayout';
import { BLUEPRINT_SP_MENU } from '../utils/constants';

import BlueprintSpTemplate from '../templates/BlueprintSpTemplate';

const Blueprint = () => {
  return (
    <BlueprintLayout menuItems={BLUEPRINT_SP_MENU}>
      <BlueprintSpTemplate />
    </BlueprintLayout>
  );
};

export default Blueprint;

