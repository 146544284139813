import { PageMenuItem } from '../components/interfaces/page-menu-item.interface';

export const BLUEPRINT_MENU: PageMenuItem[] = [
  { displayText: 'Setting the Context', link: 'context' },
  { displayText: 'Premises of the INN Platform', link: 'premises' },
  {
    displayText: 'Goals of the INN Platform',
    link: 'goals',
  },
  { displayText: 'Audience for the INN Platform', link: 'audience' },
  { displayText: 'Methodology Behind the INN Platform', link: 'methodology' },
  { displayText: 'Common Struggles & Strengths', link: 'common' },
  { displayText: 'Big-Picture Takeaways', link: 'bigPicture' },
];

export const BLUEPRINT_SUB_MENU = [
  { displayText: 'Policy/Advocacy', link: 'advocacy' },
  { displayText: 'Direct Services', link: 'directServices' },
  {
    displayText: 'Innovative Alternatives to Traditional Prisons',
    link: 'innovative',
  },
];

export const BLUEPRINT_PORT_MENU: PageMenuItem[] = [
  { displayText: 'CONTEXTO', link: 'notes' },
  { displayText: 'PREMISSAS DA PLATAFORMA INN', link: 'premises' },
  {
    displayText: 'OBJETIVOS DA PLATAFORMA INN',
    link: 'goals',
  },
  { displayText: 'PÚBLICO DA PLATFORMA INN', link: 'audience' },
  {
    displayText: 'METODOLOGÍA ATRÁS DA PLATAFORMA',
    link: 'methodology',
  },
  { displayText: 'LUTAS & FORÇAS EM COMUM', link: 'common' },
  { displayText: 'PRINCIPAIS CONCLUSÕES', link: 'bigPicture' },
];

export const BLUEPRINT_FR_MENU: PageMenuItem[] = [
  { displayText: 'Remarques et Reflexions', link: 'context' },
  { displayText: 'LES LOCAUX DE LA PLATE-FORME DU RNI', link: 'premises' },
  {
    displayText: 'OBJECTIFS DE LA PLATEFORME RNI',
    link: 'goals',
  },
  { displayText: 'AUDIENCE POUR LA PLATEFORME RNI', link: 'audience' },
  {
    displayText:
      'METHODOLOGIE DE COLLECTE DE DONNEES / ORGANISATION DE LA PLATEFORME DU RNI',
    link: 'methodology',
  },
  { displayText: 'LUTTES ET FORCES COMMUNES', link: 'common' },
  {
    displayText: 'GRANDES IMAGES À PARTIR DE LA PLATE-FORME',
    link: 'bigPicture',
  },
];

export const BLUEPRINT_SP_MENU: PageMenuItem[] = [
  { displayText: 'CONTEXTO', link: 'context' },
  { displayText: 'PREMISAS DE LA PLATAFORMA INN', link: 'premises' },
  {
    displayText: 'METAS DE LA PLATAFORMA INN',
    link: 'goals',
  },
  { displayText: 'EL PÚBLICO DE LA PLATAFORMA INN', link: 'audience' },
  {
    displayText: 'LA METODOLOGÍA DETRÄS DE LA PLATAFORMA',
    link: 'methodology',
  },
  { displayText: 'LUCHAS Y FORTALEZAS COMPARTIDAS', link: 'common' },
  {
    displayText: 'APRENDIZAJES GENERALES DE LA PLATAFORMA',
    link: 'bigPicture',
  },
];

export const BLUEPRINT_AR_MENU: PageMenuItem[] = [
  { displayText: 'أسس المنصّة', link: 'premises' },
  {
    displayText: 'أهداف المنصّة',
    link: 'goals',
  },
  { displayText: 'جمهور المنصّة', link: 'audience' },
  {
    displayText: 'تجميع البيانات من جميع المنظّمات لمنصّة',
    link: 'methodology',
  },
  { displayText: 'التحدّيات الشائعة ونقاط القوّة', link: 'common' },
  { displayText: 'النصائح الجوهريّة التي يمكن تعلّمها', link: 'bigPicture' },
];

export const POLICY_ADVOCACY_MENU: PageMenuItem[] = [
  { displayText: 'Profile', link: 'profile' },
  { displayText: 'The Big Picture', link: 'bigPicture' },
  {
    displayText: 'Strategies & Targets',
    link: 'strategiesAndTargets',
  },
  { displayText: 'Theory of Change', link: 'theoryOfChange' },
  { displayText: 'Implementation', link: 'implementation' },
  { displayText: 'Cost', link: 'cost' },
  { displayText: 'Evaluating Success', link: 'evaluatingSuccess' },
  { displayText: 'Additional Materials', link: 'additionalMaterials' },
];

export const ALTERNATIVES_MENU: PageMenuItem[] = [
  { displayText: 'Profile', link: 'profile' },
  { displayText: 'The Big Picture', link: 'bigPicture' },
  {
    displayText: 'Beneficiaries & Target Groups',
    link: 'beneficiariesAndTargets',
  },
  { displayText: 'Theory of Change', link: 'theoryOfChange' },
  { displayText: 'Implementation', link: 'implementation' },
  { displayText: 'Cost', link: 'cost' },
  { displayText: 'Evaluating Success', link: 'evaluatingSuccess' },
  { displayText: 'Additional Materials', link: 'additionalMaterials' },
];

export const PRISON_ALTERNATIVE_MENU: PageMenuItem[] = [
  { displayText: 'Profile', link: 'profile' },
  { displayText: 'The Big Picture', link: 'bigPicture' },
  {
    displayText: 'Beneficiaries & Target Groups',
    link: 'beneficiariesAndTargets',
  },
  { displayText: 'Theory of Change', link: 'theoryOfChange' },
  { displayText: 'Implementation', link: 'implementation' },
  { displayText: 'Cost', link: 'cost' },
  { displayText: 'Evaluating Success', link: 'evaluatingSuccess' },
  { displayText: 'Additional Materials', link: 'additionalMaterials' },
];

export const DIRECT_SERVICES_MENU: PageMenuItem[] = [
  { displayText: 'Profile', link: 'profile' },
  { displayText: 'The Big Picture', link: 'bigPicture' },
  {
    displayText: 'Beneficiaries & Target Groups',
    link: 'beneficiariesAndTargets',
  },
  { displayText: 'Theory of Change', link: 'theoryOfChange' },
  { displayText: 'Implementation', link: 'implementation' },
  { displayText: 'Cost', link: 'cost' },
  { displayText: 'Evaluating Success', link: 'evaluatingSuccess' },
  { displayText: 'Additional Materials', link: 'additionalMaterials' },
];
