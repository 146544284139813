import * as React from 'react';

import Hamburger from '../../Hamburger/Hamburger';
import { BLUEPRINT_MENU } from '../../../../utils/constants';
import PageMenu from '../../PageMenu/PageMenu';
import { PageMenuItem } from '../../../interfaces/page-menu-item.interface';

const MobileBlueprintNav = ({ menuItems }: { menuItems?: PageMenuItem[] }) => {
  const [showNav, setShowNav] = React.useState(false);

  const onToggleNav = () => {
    // console.log('onToggleNav');
    setShowNav(prevState => !prevState);
  };

  let items = menuItems ? menuItems : BLUEPRINT_MENU;

  return (
    <>
      <Hamburger onClick={onToggleNav} />
      {showNav && (
        <div style={{ position: 'absolute' }}>
          <PageMenu menuItems={items} onScrollEnd={onToggleNav} offset={-100} />
        </div>
      )}
    </>
  );
};

export default MobileBlueprintNav;
