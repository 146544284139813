import React from 'react';
import classnames from 'classnames';

// import DBProgramsByRegion from '../images/charts/chart_database-programs-by-region_spanish.png';
// import DBProgramsByType from '../images/charts/chart_database-programs-by-type_spanish.png';
import INNPartnerByRegion from '../images/charts/chart_deep-dive-programs-by-region_spanish.png';
import INNPartnerByType from '../images/charts/chart_deep-dive-programs-by-type_spanish.png';

const BlueprintSpTemplate = () => {
  return (
    <>
      <div style={{ position: 'relative' }}>
        <article className={'module-featured'}>
          <h1>
            LA PLATAFORMA GLOBAL DE LA RED DE NACIONES DEL ENCARCELAMIENTO:
          </h1>
          <p>Apuntes y Reflexiones</p>
          <figure>
            <img
              src="https://www.berlin.de/converjon/?ts=1418132797&width=540&height=270&url=https%3A%2F%2Fwww.berlin.de%2Fjustizvollzug%2F_assets%2Fjva-heidering%2Fdie-anstalt%2Fpforte.jpg"
              alt="Placeholder"
              width="1440"
              height="710"
            />
          </figure>
        </article>
        <article className={'s16'} id={'context'}>
          <header className={'text-center'}>
            <h2 style={{ color: 'black' }}>
              El encarcelamiento en masa es una tragedia global.
            </h2>
          </header>
          <div className={'triple'}>
            <div>
              <p>
                Cerca de 11 millones de personas están privadas de su libertad
                actualmente en el mundo. Este número está en aumento constante.
                Entre el 2008 y el 2011, la población privada de su libertad
                creció en el 78 por ciento de todos los países y entre el 2000 y
                el 2016, tuvo un aumento de casi el 20 por ciento. Entre el 2000
                y el 2015, la población total en Oceanía aumentó casi el 60 por
                ciento: en las Américas aumentó el 40 por ciento en todos los
                países (14 por ciento en los Estados Unidos; más del 80 por
                ciento en los países de Centro América; 145 por ciento en los
                países de Sur América). La cantidad de personas privadas de la
                libertad con cadena perpetua casi se ha duplicado en todo el
                mundo desde el 2000.
              </p>
            </div>
            <div>
              <p>
                ás de 3.2 de los 11 millones de personas privadas de la libertad
                no han sido condenadas, son personas legalmente inocentes que
                esperan un juicio. Una de cada cinco de ellas es privada de su
                libertad por delitos relacionados con drogas, 83 por ciento por
                posesión de drogas. Un informe del{' '}
                <a
                  style={{ color: 'black' }}
                  href="https://justice.sdg16.plus/report"
                >
                  Grupo de Trabajo sobre Justicia
                </a>{' '}
                calculó que 4.400 millones de personas están excluidas de la
                oportunidad que brinda la ley y 244 millones de personas
                experimentan condiciones extremas de injusticia.
              </p>
            </div>

            <div>
              <p>
                A pesar de ser conocido como encarcelamiento en masa, no afecta
                a las masas por igual. Las comunidades que han sido construidas
                como un "otro", ya sean afroamericanas y latinas en los Estados
                Unidos; pueblos indígenas en Canadá, Australia y Nueva Zelanda;
                romaníes en toda Europa; las y los pobres de todo el Sur Global
                son el objeto del encarcelamiento en masa y, por lo tanto, se
                ven implicadas en los sistemas de justicia penal de manera
                altamente desproporcional, debido a una matriz de realidades
                históricas y actuales basadas en normas capitalistas.
              </p>
            </div>
            <div>
              <figure>
                <img
                  style={{ minHeight: '625px', objectFit: 'cover' }}
                  className={'desktop-only'}
                  src="https://static1.squarespace.com/static/57cd909c197aea06e48be99b/t/5ba24f944d7a9c88ca636974/1537363932300/app_at_work.JPG"
                  alt="Placeholder"
                  width="400"
                  height="625"
                />
                <img
                  className={'desktop-hide'}
                  src="https://static1.squarespace.com/static/57cd909c197aea06e48be99b/t/5ba24f944d7a9c88ca636974/1537363932300/app_at_work.JPG"
                  alt="Placeholder"
                  width="360"
                  height="295"
                />
              </figure>
            </div>
            <div>
              <p>
                Esto no es solo una crisis de derechos humanos. También es una
                profunda amenaza mundial a la paz y la seguridad comunitaria,
                porque las cárceles son un método anticuado y poco innovador
                para generar seguridad pública y reducir la delincuencia, no
                construyen comunidades más seguras ni promueven la paz y la
                justicia. Una gran cantidad de datos lo demuestra. Un informe
                del{' '}
                <a
                  style={{ color: 'black' }}
                  href="https://penphilanthropy.org/files/Focus_Areas/Criminal_Justice_Reform/The_impacts_of_incarceration_on_crime_10.pdf"
                >
                  Proyecto Open Philanthropy 2017
                </a>{' '}
                revisó 35 estudios internacionales y concluyó que las sentencias
                más duras “no disuaden el crimen” y el tiempo en prisión “tiende
                a aumentar la criminalidad [de una persona] después de su
                liberación”. En otras palabras, las cárceles producen delitos en
                lugar de detenerlos. Un estudio del{' '}
                <a
                  href="https://Nature.com/articles/s41562-019-0604-8.epdf"
                  style={{ color: 'black' }}
                >
                  {' '}
                  2019 de la Universidad de California en Berkeley
                </a>{' '}
                descubrió que el encarcelamiento no disuade a las personas de
                cometer delitos violentos, y privarlas de la libertad casi no
                tiene efecto sobre la reducción del crimen violento en general.
              </p>
              <p>
                La Red de Naciones del Encarcelamiento (INN, por sus siglas en
                inglés) nace a partir de esta situación: es una red global y un
                grupo de expertos que apoya, instiga, y populariza los esfuerzos
                innovadores de reinvención de la prisión y la justicia en todo
                el mundo.{' '}
              </p>
            </div>
            <div>
              <figure className={'m35'}>
                <img
                  src="https://static.wixstatic.com/media/b8d0a9_816c8efd1eec47eab0fed67369ea700f~mv2_d_4608_3456_s_4_2.jpg/v1/fill/w_899,h_300,al_c,q_80,usm_0.66_1.00_0.01/b8d0a9_816c8efd1eec47eab0fed67369ea700f~mv2_d_4608_3456_s_4_2.webp"
                  alt="Placeholder"
                  width="400"
                  height="175"
                />
              </figure>
              <p>
                Esta plataforma multimedia de la INN es hasta donde sabemos la
                primera de su tipo y nace de esta crisis global. Está impulsada
                por una pasión por las soluciones, tanto grandes como pequeñas.
                Con sus esfuerzos innovadores de reinvención y reforma
                penitenciaria en todo el mundo, consiste en una base de datos de
                amplio alcance que recopila casi 800 programas globales y una
                exploración profunda de 82 programas particularmente
                prometedores con una gama de actividades y enfoques, que hemos
                agrupado en categorías.
              </p>
            </div>
          </div>
        </article>
        <article className={'module-wide'} id={'premises'}>
          <header className={'text-center'}>
            <h2 style={{ color: 'black' }}>PREMISAS DE LA PLATAFORMA INN</h2>
            <p style={{ color: '#7D7D7D' }}>
              La plataforma INN tiene tres premisas a priori sobre la justicia
            </p>
          </header>
          <div className={classnames('double', 'a')}>
            <div className={'m0'}>
              <ul>
                <li>
                  <p>
                    Los modelos de justicia no basados en las cárceles, sino en
                    la justicia restaurativa y otros enfoques de distracción son
                    formas más humanas y efectivas en la construcción de
                    comunidades seguras y justas que las cárceles y la
                    detención. Estas no deberían ser “alternativas al
                    encarcelamiento” sino el acto principal de la justicia, por
                    así decirlo. Además, esto debería reformular nuestras
                    preguntas fundamentales sobre la justicia. En lugar de
                    simplemente preguntar, “¿qué programas de rehabilitación de
                    prisiones son efectivos?” Necesitamos hacer preguntas que
                    nos muevan más allá de las cárceles: “¿Qué crea comunidades
                    seguras? ¿Cuáles son las necesidades de los perjudicados y
                    cómo podemos abordarlos?”
                  </p>
                </li>
                <li>
                  <p>
                    Aunque el cambio sistémico radical y la imaginación
                    innovadora a gran escala son lo que más se necesita para
                    mover la aguja global hacia la justicia, la reforma y los
                    esfuerzos graduales tienen su propia importancia, porque hay
                    millones de vidas en juego aquí y ahora. Reducir la
                    población de personas privadas de la libertad y mejorar las
                    condiciones carcelarias es sumamente importante para
                    defender los derechos humanos y la seguridad comunitaria.
                    Por lo tanto, es posible, aunque sea paradójico, participar
                    en un trabajo de reforma que salve vidas mientras se trabaja
                    hacia varios modos de abolición de la prisión que obvian
                    tales reformas.
                  </p>
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li>
                  <p>
                    Cuando se trata de construir comunidades seguras, nada
                    supera la prevención. Esto se traduce en una sociedad basada
                    en la igualdad, la equidad, las oportunidades económicas y
                    los servicios sociales íntegros. Como bien lo sabe el
                    movimiento de Reinversión de Justicia, el verdadero trabajo
                    para hacer justicia no puede realizarse en un vacío: es
                    holístico y de gran alcance, porque busca transformar todas
                    las facetas de una sociedad en nombre de la paz.
                  </p>
                </li>
              </ul>
              <figure>
                <img
                  src="https://www.score.gov.sg/images/default-source/default-album/chef-working-in-kitchen.jpg"
                  alt="Placeholder"
                  width="785"
                  height="280"
                />
              </figure>
            </div>
          </div>
        </article>
        <article className={'module-wide'} id={'goals'}>
          <figure className={'background'}>
            <img
              src="https://mediaprocessor.websimages.com/www.corkalliancecentre.com/patricks%20hill%20view.jpg"
              alt="Placeholder"
              width="1440"
              height="800"
            />
          </figure>
          <header className={'text-center'}>
            <h2>METAS DE LA PLATAFORMA INN</h2>
            <p>La INN tiene múltiples metas para la plataforma multimedia</p>
          </header>
          <div className={'triple'}>
            <div>
              <ul>
                <li>
                  Crear un plan y una hoja de ruta para llevar acabo soluciones
                  prometedoras a la crisis penitenciaria mundial.
                </li>
                <li>
                  Demostrar que las conversaciones sobre la reforma y la
                  reinvención de las cárceles pueden ocurrir a nivel mundial y
                  pueden atravesar las fronteras, sin dejar de reconocer las
                  limitaciones de las generalizaciones de un país a otro.
                </li>
                <li>
                  Construir una coalición que atraviese las fronteras y refuerce
                  las organizaciones que realizan trabajo para la justicia de
                  vital importancia, particularmente las organizaciones que
                  realizan este trabajo y prestan servicios a las poblaciones
                  carcelarias en entornos públicos hostiles y punitivos.
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li>
                  Construir una base de datos de amplio alcance para que las
                  organizaciones globales que realizan trabajo de reforma
                  penitenciaria, las y los académicos, las y los investigadores
                  y las y los innovadores de todo el mundo puedan, literalmente,
                  estar en la misma página.
                </li>
                <li>
                  Generar una red global de creadores de cambio que permita
                  replicar programas potentes en todo el mundo.
                </li>
                <li>
                  Provocar conversaciones que crucen las fronteras sobre las
                  luchas y los éxitos compartidos.
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li>
                  Combinar datos con imaginación para atraer un nuevo conjunto
                  de ojos y oídos a la crisis mundial de encarcelamiento en
                  masa, especialmente las y los actores más importantes en este
                  trabajo: nuevos financiadores, el público en general y los
                  medios de comunicación.
                </li>
                <li>
                  Proporcionar un recurso práctico para organizaciones en países
                  con personas privadas de la libertad nacidas en el extranjero
                  que pueden beneficiarse de las conexiones con organizaciones
                  en el extranjero.
                </li>
              </ul>
            </div>
          </div>
        </article>
        <article className={'module-wide'} id={'audience'}>
          <header className={'text-center'}>
            <h2 style={{ color: 'black' }}>EL PÚBLICO DE LA PLATAFORMA INN</h2>
            <p style={{ color: '#7D7D7D' }}>
              El público de la plataforma global de la INN es amplio, incluye
            </p>
          </header>
          <div className={'module-aside'}>
            <figure>
              <img
                style={{ maxHeight: '330px', objectFit: 'cover' }}
                src="https://www.crcnsw.org.au/wp-content/uploads/2016/10/highlight2.jpg"
                alt="Placeholder"
                width="865"
                height="330"
              />
            </figure>
            <div>
              <p>
                Agencias gubernamentales y ministerios; ONG que trabajan en el
                campo de la justicia; medios de comunicación, académicos y
                estudiantes en campos relacionados con la justicia; potenciales
                financiadores del sector corporativo; organizaciones de
                filantropía y otros financiadores; activistas y ciudadanos
                interesados; personas privadas de la libertad actualmente o
                anteriormente “que viven en el closet”, donde luchan contra el
                estigma y la exclusión social; familias y seres queridos de la
                comunidad privada de la libertad.
              </p>
            </div>
          </div>
        </article>
        <article className={'mn'} id={'methodology'}>
          <header className={'text-center'}>
            <h2 style={{ color: 'black' }}>
              LA METODOLOGÍA PARA LA RECOLECCIÓN DE DATOS/ORGANIZACIÓN DE LA
              PLATAFORMA INN
            </h2>
          </header>
          <div className={'double a module-spaces tablet-compact'}>
            <div>
              <p>
                Para la base de datos, que contiene casi 800 programas en todo
                el mundo, la INN trabajó con su equipo de consultores de
                justicia global, así como con asesores académicos y miembros del
                equipo de varias fundaciones. La INN también participó en
                búsquedas sistemáticas en la web utilizando palabras clave junto
                con los nombres de los países ("prisión", "reforma
                penitenciaria", "justicia restaurativa", "desvío", "alternativa
                al encarcelamiento", "desvío del tribunal / delincuente"). Se
                incluyeron todos los programas con un sitio web funcional e
                información de contacto. Hasta donde sabe la INN, la base de
                datos es el único mapeo global de este tipo y este alcance, pero
                no pretende ser absoluto. La búsqueda en la web se realizó
                principalmente en inglés y español.
              </p>
              <p>
                Al seleccionar programas para incluir en la sección de Inn
                Partner de la plataforma, la INN trabajó con sus consultores de
                justicia global y solicitó recomendaciones de programas
                especialmente prometedores. También se consultaron contactos
                sobre el terreno de contextos académicos y de ONG. Se dio
                prioridad a los programas en los que las personas directamente
                impactadas juegan un papel central, porque la INN cree, como ha
                afirmado JustLeadership USA, que “los más cercanos al problema
                son los más cercanos a la solución”.
              </p>
              <p>
                En la categorización de los programas en la base de datos, la
                INN utilizó información disponible públicamente. Las
                organizaciones en la plataforma INN Partner clasificaron sus
                propios programas. A menudo, una organización/institución
                ejecuta múltiples tipos de programas, cada uno de los cuales
                figura como un programa individual separado. Muchas
                organizaciones participan en una combinación de servicio directo
                y defensa, y a menudo no las ven como "programas" separados;
                categorizamos este trabajo sinérgico en tipos de programas
                separados.
              </p>
            </div>
            <div>
              <p>
                Las organizaciones participantes en la plataforma INN Partner
                recibieron un cuestionario de 50 preguntas (
                <span className="strong">
                  <a
                    style={{ color: 'black' }}
                    href="https://assets.ctfassets.net/fhwlk5jsnns3/16pO6mhH06ep3rvEKWtAF4/a498468563c0348dae7092be6a599af5/INN_DirectServices_Questionaire_.pdf"
                  >
                    aquí
                  </a>
                  ,{' '}
                  <a
                    style={{ color: 'black' }}
                    href="https://assets.ctfassets.net/fhwlk5jsnns3/2bs7ebzwYpqUTW0e38aVz4/8b82c979473cd3023d7dc7edfe07a02b/INN_Alternatives_to_Incarceration_Questionaire_.pdf"
                  >
                    aquí
                  </a>
                  ,{' '}
                  <a
                    style={{ color: 'black' }}
                    href="https://assets.ctfassets.net/fhwlk5jsnns3/16pO6mhH06ep3rvEKWtAF4/a498468563c0348dae7092be6a599af5/INN_DirectServices_Questionaire_.pdf"
                  >
                    aquí
                  </a>{' '}
                  &{' '}
                  <a
                    style={{ color: 'black' }}
                    href="https://assets.ctfassets.net/fhwlk5jsnns3/5MOyuKg9Phsrh1yLhcub56/def96255a13b7ba334ff22bfa22bf77f/INN_DirectServices_Innovative_Alternatives_to_Traditional_Prisons_Questionaire_.pdf"
                  >
                    aquí
                  </a>
                </span>
                ) sobre su trabajo, sus métodos, sus teorías de cambio y más;
                estos cuestionarios incluyeron opciones de respuesta abierta y
                cerrada en un esfuerzo por recopilar datos cuantitativos y
                cualitativos sobre cada programa. A 39 organizaciones también se
                les enviaron encuestas de clientes{' '}
                <span className="strong">
                  <a
                    style={{ color: 'black' }}
                    href="https://assets.ctfassets.net/fhwlk5jsnns3/1HPZ9vmVLMG2nZrwsZu6X4/87c80904023e859a826f35a97e07cbeb/INN_GlobalAssessment_ClientSurvey.docx"
                  >
                    encuestas de clientes
                  </a>
                </span>{' '}
                para distribuir a cinco participantes del programa, estos se
                utilizaron para reunir datos cualitativos sobre los programas.
              </p>
              <figure className={'mn'}>
                <img
                  src="https://www.genepi.fr/wp-content/uploads/2018/03/Re%C2%A6%C3%BCflexion-et-formation.jpg"
                  alt="Placeholder"
                  width="620"
                  height="555"
                />
              </figure>
              <p>
                52 de los programas que estaban en la plataforma INN Partner
                fueron visitados por el Director Ejecutivo de la INN, Dr. Baz
                Dreisinger, incluyendo al menos uno de cada región geográfica.
                Se realizaron entrevistas con personas clave que representan a
                la organización, así como con clientes.
              </p>
              <p>
                a INN solicitó a varios programas que participar en la
                plataforma INN Partner, pero no pudieron hacerlo, por varias
                razones. De estos, la INN sintió que algunos eran demasiado
                claves como para dejarlos de lado, por lo que se crearon páginas
                para mostrar su misión, enfoque y cobertura de los medios al
                tiempo que excluían los datos más detallados, que no se podían
                recopilar en este momento.
              </p>
            </div>
          </div>
        </article>
        <article className={'module-wide'}>
          <div className={classnames('double', 'a', 'tablet-compact')}>
            <div>
              <p className={classnames('strong', 'm40')}>
                Al seleccionar programas para la plataforma INN Partner, también
                se hizo un esfuerzo por equilibrar varios factores para así
                permitir:
              </p>
              <hr />
              <ul>
                <li>
                  La inclusión de programas de una amplia gama de regiones
                  geográficas.
                </li>
                <li>
                  Una gama de programas nacionales a gran escala y de programas
                  a pequeña escala.
                </li>
                <li>
                  Una combinación relevante de programas orientados a sistemas y
                  de panorama general, así como programas de servicio directo
                  centrados en individuos que afrontan la situación y salvan
                  vidas en el aquí y ahora
                </li>
                <li>
                  La inclusión de programas establecidos y de larga duración, y
                  organizaciones con programas más pequeños y nuevos que pueden
                  aún no tener resultados probados pero cuyo modelo es
                  prometedor e innovador y, por lo tanto, se beneficiaría de una
                  presencia en una plataforma global.
                </li>
              </ul>
              <hr />
              <p>
                <span className="strong">NOTA:</span> En esta lista falta la
                prevención, incluyendo las oportunidades de servicio social, las
                intervenciones comunitarias (especialmente las que están
                orientadas a los jóvenes) y el desarrollo económico, por lo que
                esto está más allá del alcance de la plataforma de la INN en
                este momento.{' '}
              </p>
              <p>
                La INN también destacó el trabajo centrado en categorías
                relevantes que han sido directamente impactadas:
              </p>
              <ul>
                <li>
                  Los grupos que están excesivamente representados en el sistema
                  de justicia penal.
                </li>
                <li>Las mujeres en el sistema de justicia.</li>
                <li>
                  La población de personas que han sido privadas de la libertad
                  nuevamente, o que aún están esperando un juicio.
                </li>
                <li>Las y los jóvenes.</li>
                <li>Los familiares de las personas privadas de la libertad.</li>
                <li>La población LGBTQ privada de la libertad.</li>
              </ul>
            </div>
            <div>
              <p className={classnames('strong', 'm40')}>
                Identificar las categorías de organización de la plataforma fue
                el primer paso para delinear un terreno común entre los
                programas y crear un tipo de plan de soluciones o hoja de ruta.
                Estas categorías son:
              </p>
              <hr />
              <ul>
                <li>Alternativas al encarcelamiento</li>
                <li>Incidencia y reforma de políticas</li>
                <li>
                  Servicios directos para las personas afectadas por el sistema
                  penitenciario (personas privadas de la libertad o
                  anteriormente privadas de la libertad y sus familias)
                </li>
              </ul>
              <hr />
              <p>
                <span className="strong">NOTA: </span>Definir “éxito” cuando se
                trata de los esfuerzos de reforma penitenciaria sigue siendo un
                asunto complejo que contiene muchos matices. La reincidencia se
                usa comúnmente como una medida, pero como las y los
                investigadores y profesionales observan continuamente, tales
                números a menudo son engañosos y simplistas. (Brasil y El
                Salvador, por ejemplo, tienen tasas de reincidencia en general
                más bajas que Chile, pero esto no necesariamente habla del éxito
                de los sistemas penitenciarios de los primeros países;
                simplemente se puede atribuir a las breves sentencias repartidas
                en Chile, lo que resulta en la entrada y salida de personas del
                sistema a tasas más altas). Dada esta realidad, la INN preguntó
                explícitamente a las organizaciones sobre sus ideas sobre el
                éxito (con preguntas como “¿qué haría que su trabajo fuera
                innecesario?”, “¿Cuál es la visión de su programa para un cambio
                transformador a largo plazo?”). Además, buscaron reunir datos
                tanto cuantitativos como cualitativos sobre los programas al
                mostrar su trabajo. Hay más información sobre esto y sobre el
                tema de la práctica basada en la evidencia en la sección
                “Reflexiones del panorama general”.
              </p>
              <p>
                <span className="strong">NOTA: </span>En nombre de la
                accesibilidad, un principio rector de todo el trabajo de la INN,
                esta plataforma utiliza una terminología popular que la INN
                considera problemática. Como se mencionó anteriormente, la frase
                “alternativa al encarcelamiento” coloca erróneamente a la
                prisión en el corazón de un sistema de justicia. Términos como
                “rehabilitación” y “reingreso” o “reinserción” implican que las
                personas privadas de la libertad estaban habilitadas y/o
                insertadas en la sociedad en primer lugar, cuando en realidad
                sabemos que lo contrario es cierto: globalmente, las personas
                privadas de la libertad provienen en su mayoría de comunidades
                marginadas y desfavorecidas y, por lo tanto, cualquier
                oportunidad que se les brinde en prisión es probablemente una
                primera oportunidad, no una segunda oportunidad.{' '}
              </p>
              <p>
                <span className="strong">NOTA: </span>La sección de Inn Partner
                de esta plataforma no es una evaluación de programas que
                utilizan una herramienta de medición universal; los programas
                fueron seleccionados porque ya se anuncian como valiosos y vale
                la pena presentarlos como modelos potentes. En cambio, la
                sección es una muestra de las luchas y fortalezas compartidas
                por los programas a través de las fronteras y un subrayado del
                trabajo fuerte en una variedad de contextos. Se puede encontrar
                más discusión sobre esto en la sección “Reflexiones del panorama
                general”.
              </p>
            </div>
          </div>
        </article>
        <article className={'module-featured'} id={'common'}>
          <h1>LUCHAS Y FORTALEZAS COMPARTIDAS</h1>

          <figure>
            <img
              src="https://i.vimeocdn.com/video/693991407.webp?mw=1440&mh=710&q=70"
              alt="Placeholder"
              width="1440"
              height="710"
            />{' '}
            <i className={'icon-pluses'}></i>
          </figure>
        </article>
        {/* <div>
          <PageMenu menuItems={BLUEPRINT_SUB_MENU} />
        </div> */}
        <article>
          <header className={'text-center'}>
            <h2 style={{ color: 'black' }}>
              LA BASE DE DATOS PRINCIPAL (783 PROGRAMAS)
            </h2>
          </header>

          <div>
            <h3 style={{ textAlign: 'center' }}>Cuadro 1</h3>
            <p style={{ textAlign: 'center' }}>
              Programas de la base de datos por región y tipo
            </p>
          </div>

          <figure style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src="https://images.ctfassets.net/fhwlk5jsnns3/6IH0a3OG2w9sMO9yR9aTPn/23a6d3c2ab0aff0b468c5b370c6ff62f/table1.png"
              alt="Placeholder"
              width="650"
              height="650"
            />
          </figure>

          <div>
            <h3 style={{ textAlign: 'center' }}>Figura 2</h3>
            <p style={{ textAlign: 'center' }}>
              Programas de INN Partner por región y tipo
            </p>
          </div>

          <figure style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={INNPartnerByRegion}
              alt="Placeholder"
              width="650"
              height="650"
            />
          </figure>
          <figure style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={INNPartnerByType}
              alt="Placeholder"
              width="650"
              height="650"
            />
          </figure>

          <div>
            <h3 style={{ textAlign: 'center' }}>Cuadro 2</h3>
            <p style={{ textAlign: 'center' }}>
              Programas de INN Partner por región y tipo
            </p>
          </div>

          <figure style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src="https://images.ctfassets.net/fhwlk5jsnns3/oVo7xJsAgrsHPjYGiSMlf/a2d18e741cacbfc3df628de2fce98950/table2.png"
              alt="Placeholder"
              width="650"
              height="650"
            />
          </figure>
        </article>
        <article className={classnames('module-wide', 'overlay-b')}>
          <div id={'alternatives'}>
            <header className={'text-center'}>
              <h2 style={{ color: 'black' }}>
                ALTERNATIVAS AL ENCARCELAMIENTO
              </h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUCHA COMPARTIDA:
                </h3>
                <p>
                  A nivel mundial, hay una escasez de los programas alternativos
                  al encarcelamiento (PAE). La INN localizó servicios y
                  programas de promoción / política mucho más directos que los
                  PAE; De todos los programas en la Base de Datos en el momento
                  de la evaluación de datos, solo el 14% (111 programas) eran
                  PAE (en comparación con el 60% de servicios directos y el 26%
                  de defensa/política). De aquellos en la Inn Partner, el 63% de
                  las PAE mencionaron su objetivo inmediato y a corto plazo como
                  “prácticas piloto de RJ”, lo que sugiere una gran necesidad de
                  desarrollo y apoyo de nuevos programas en este campo.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUCHA COMPARTIDA:
                </h3>
                <p>
                  Casi un tercio (31.1%) de los programas de PAE de Inn Partner
                  mencionaron el problema principal que pretenden abordar como
                  “Discriminación/sobrerrepresentación de ciertos grupos
                  (basados ​​en la cultura indígena o la raza) en las cárceles”.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUCHA COMPARTIDA:
                </h3>
                <p>
                  Donde existen PAE fuertes (especialmente programas de justicia
                  restaurativa), casi siempre son solo para menores. Parece
                  haber una gran vacilación al brindar estas oportunidades a los
                  adultos, quienes, después de todo, alguna vez fueron menores
                  que también enfrentaban un sistema perjudicial.
                </p>
                <figure>
                  <img
                    style={{ maxHeight: '200px', objectFit: 'cover' }}
                    src="https://cdhep.org.br/wp-content/uploads/2019/04/Curso_V%C3%ADtima_Ofensor_Comunidade_1.jpg"
                    alt="Placeholder"
                    width="415"
                    height="200"
                  />
                </figure>
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORTALEZA COMPARTIDA:
                </h3>
                <p>
                  Los PAE han existido durante mucho tiempo: en promedio, 18
                  años, son los programas más antiguos de las tres categorías
                  principales examinadas en la Inn Partner. Los PAE también
                  tienen un amplio alcance, con el mayor número promedio de
                  participantes de las tres categorías de programas. Los PAE
                  tienen el personal más grande entre los programas de Deep
                  Dive, pero también dependen en gran medida de voluntarios; en
                  promedio, el 30% de las personas involucradas en las
                  organizaciones no son personal remunerado. Los PAE también
                  tienen más probabilidades de tener al menos un miembro del
                  personal encarcelado o anteriormente encarcelado (30% de los
                  programas).
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORTALEZA COMPARTIDA:
                </h3>
                <p>
                  Los PAE son significativamente más baratos que los programas
                  de servicio directo (calculados como el costo total del
                  programa o el costo por beneficiario). Existe una amplia gama
                  de costos anuales del programa (de $4000 a 15 millones de
                  dólares), pero la alternativa más costosa al programa de
                  encarcelamiento es tres veces más barata que el programa de
                  servicios directos más costoso.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORTALEZA COMPARTIDA:
                </h3>
                <p>
                  Para la mitad de los PAE, la visión transformadora a largo
                  plazo para el cambio fue “La sentencia alternativa/justicia
                  restaurativa es la respuesta principal a los delitos o
                  condenas”.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'restorative'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>JUSTICIA RESTAURATIVA</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <p>
                  <span className="strong">NOTA: </span> Muchos PAE involucran
                  aspectos de la justicia restaurativa y jurisprudencia
                  terapéutica (en Nueva Zelanda, por ejemplo, la Ley de
                  Sentencias de 2002 contiene disposiciones restaurativas claras
                  que obligan a los jueces de sentencia a tener en cuenta los
                  procesos restaurativos). La INN clasificó un programa como un
                  programa de justicia restaurativa solo si se etiqueta
                  explícitamente como tal.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUCHA COMPARTIDA:
                </h3>
                <p>
                  En muchas partes del mundo, la justicia restaurativa sigue
                  siendo un concepto desconocido o vago para el público en
                  general: se enfrenta a una crisis de relaciones públicas. Un
                  programa estadounidense tuvo que dejar de describir su trabajo
                  como “conferencia comunitaria” porque se confundió con una
                  empresa que alquila espacio para conferencias. También
                  afirmaron que todavía es una opción desconocida por muchas
                  escuelas y sistemas judiciales en el área, a pesar de haber
                  existido durante más de dos décadas.
                </p>
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORTALEZA COMPARTIDA:
                </h3>
                <p>
                  Las y los facilitadores hábiles que participan en lo que
                  Restorative Response Baltimore (de los Estados Unidos) llama
                  un “modelo de crecimiento continuo” son de vital importancia
                  para el éxito de cualquier intervención de justicia
                  restaurativa, en contraste con las capacitaciones de
                  facilitadores y facilitadoras que son de amplio alcance y se
                  realizan una sola vez, las cuales a menudo diluyen el proceso
                  o los conceptos, además de capacitar a grandes cantidades de
                  personas que pueden no ser los candidatos ideales para el rol
                  de facilitador, ya que este es muy complejo.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORTALEZA COMPARTIDA:
                </h3>
                <p>
                  Los programas de justicia restaurativa se seleccionaron para
                  la plataforma INN Partner en parte porque orienta a las
                  personas hacia medidas alternativas y/o eluden el sistema de
                  justicia penal tradicional en lugar de actuar como una adición
                  al mismo (por ejemplo, programas que trabajan con personas que
                  ya están privadas de la libertad). La INN se siente más
                  entusiasmada con estos programas porque son enfoques de
                  justicia no basados en las cárceles.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'diversion'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>CREACIÓN DE MEDIDAS ALTERNAS</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUCHA COMPARTIDA:
                </h3>
                <p>
                  Muchos programas de creación de medidas alternas implican
                  redirigir a las personas a programas externos en lugar de la
                  prisión (tratamiento de drogas/alcohol, tutorías, servicios de
                  duelo, etc.); con ese fin, el 43% de los PAE identificaron “la
                  colaboración con socios externos” como el elemento clave para
                  el éxito. Sin embargo, muchas organizaciones expresan que
                  dichos programas no cuentan con recursos suficientes y/o
                  carecen de varios aspectos, especialmente con respecto a las
                  sensibilidades culturales hacia los grupos
                  sobrerrepresentados. Los Aboriginal Legal Services (Canadá)
                  responden a este problema con un enfoque valioso: convertirse
                  en un programa con un solo enfoque y crear programas internos
                  que son hechos por y para los pueblos indígenas.
                </p>
                <img
                  className={'desktop-only'}
                  src="https://www.incarcerationnationsnetwork.com/wp-content/uploads/2016/02/bxp41924s.jpg"
                  alt="Placeholder"
                  width="400"
                  height="625"
                />
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORTALEZA COMPARTIDA:
                </h3>
                <p>
                  Los planes de medidas alternas holísticos y personalizados son
                  de vital importancia: no existe una fórmula única para todos.
                  En programas sólidos, varias agencias trabajan en sinergia,
                  con un conocimiento personalizado de cada persona antes que
                  ellas y en colaboración con las familias, trabajadores
                  sociales, educación y sistemas de salud. En Nueva Zelanda, por
                  ejemplo, las Conferencias de Grupos Familiares, la Corte
                  Juvenil general y Rangatahi trabajan juntas para generar
                  planes personalizados; El Red Hook Community Justice Center
                  (EE. UU.) se autodenomina “centrado en la persona” y no
                  clasifica a los clientes ni siquiera por tipo de delito. En
                  los Países Bajos, Halt ve a 16,000 jóvenes por año, un tercio
                  de todas y todos los niños privados de la libertad en el país,
                  pero aún así logran crear planes de medidas alternas
                  individualizados para cada uno de ellos, y cada proceso toma
                  un promedio de tres meses. El programa evoluciona e innova
                  constantemente, avanzando hacia la prevención al trabajar en
                  escuelas, experimentar con el trabajo en varios grupos
                  etarios, y utilizar juegos y realidad virtual en las tareas de
                  aprendizaje. El 75% de las y los que llevan a cabo medidas
                  alternas no cometen nuevos delitos.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORTALEZA COMPARTIDA:
                </h3>
                <p>
                  Los tribunales indígenas en Nueva Zelanda y Australia, así
                  como el Centro de Justicia Comunitaria Red Hook (EE. UU.),
                  revelan las formas a veces pequeñas y sutiles en las que el
                  entorno legal puede humanizarse y hacerse culturalmente
                  relevante para reflejar lo que las y los fundadores del El
                  Tribunal Koori en Australia llaman “saberes en lugar de
                  leyes”. Esto incluye una falta de formalidad excesiva,
                  permitir al acusado una voz fuerte y un papel central en el
                  proceso, enfatizar la comunidad a través de rituales,
                  canciones, tocar manos y caras, junto con la presencia clave
                  de ancianos/personas respetadas; hablar en inglés sencillo y
                  no “jerga legal”, eliminar las barreras físicas construidas
                  por la ley occidental (blanca) y crear una disposición de
                  asientos que lo refleje, a diferencia de las jerarquías y el
                  poder (es decir, el juez no está sentado en una plataforma
                  elevada), tomarse el tiempo del proceso en lugar de
                  apresurarse por un estilo de justicia de línea de montaje,
                  prestar atención al diseño del espacio de la sala del tribunal
                  (presencia de luz natural y/o murales de bienvenida y evitar
                  la madera oscura; usando lenguaje respetuoso (“por favor” y
                  “gracias”) en la señalización en todo momento.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'mediation'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>MEDIACIÓN</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUCHA COMPARTIDA:
                </h3>
                <p>
                  En los países de todo el mundo, los sistemas legales están
                  saturados, las y los abogados (o abogados para clientes que no
                  pueden pagar) son escasos, y la mejor respuesta es un enfoque
                  de justicia de abajo hacia arriba, en lugar de arriba hacia
                  abajo: creando medidas alternas para las personas incluso
                  antes de entrar en la esfera legal. Las y los abogados
                  formales a menudo no son necesarios para avanzar hacia
                  resoluciones. El empoderamiento legal y los pacificadores
                  comunitarios, como los del Programa de Mediación de Madaripur
                  en Bangladesh y el Programa de Creación de Paz del Centro de
                  Justicia Comunitaria Red Hook (EE. UU.), en el que los
                  pacificadores son entrenados por la Nación Navajo, son de
                  vital importancia. El desarrollo de iniciativas de
                  pacificación comunitaria apoyadas por el gobierno, como el Red
                  Hook Community Justice Center, es un área propicia para el
                  crecimiento.
                </p>
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORTALEZA COMPARTIDA:
                </h3>
                <p>
                  En la sociedad navajo,
                  <span style={{ fontStyle: 'italic' }}> k’e</span> es un
                  sistema de parentesco con valores tales como el respeto, la
                  amabilidad, la cooperación, la amistad y las relaciones
                  recíprocas. El comportamiento que infringe las reglas de k’e
                  puede entenderse como “actuar como si él o ella no tuviera
                  parientes”. Los programas de mediación exitosos pueden
                  aprovechar esos valores comunitarios y familiares para lograr
                  la paz.
                </p>
              </div>
            </div>
          </div>

          <hr />
          <div id={'advocacy'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>
                INCIDENCIA Y REFORMA DE POLÍTICAS
              </h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUCHA COMPARTIDA:
                </h3>
                <p>
                  En todo el mundo, las organizaciones de reforma penitenciaria
                  abogan por muchas de las mismas cosas, en términos generales:
                  1. Reforma a las políticas sobre delitos menores y/o la guerra
                  draconiana contra las drogas; 2. Reforma de sentencia; 3.
                  Mejoramiento de las condiciones carcelarias; 4. Política
                  previa al juicio/libertad bajo fianza. La última (política
                  previa al juicio) es especialmente universalmente relevante,
                  nombrada por el 50% de los programas de defensa de INN Partner
                  como una de las áreas más importantes de la política que
                  buscan influir.
                </p>
                <img
                  style={{
                    minHeight: '295px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="https://www.incarcerationnationsnetwork.com/wp-content/uploads/2016/02/8109-org.jpg"
                  alt="Placeholder"
                  width="360"
                  height="295"
                />
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORTALEZA COMPARTIDA:
                </h3>
                <p>
                  Muchas organizaciones están utilizando los mismos métodos para
                  abordar los problemas anteriores: 1. Dialogo con los políticos
                  responsables; 2. Litigios estratégicos; 3. Cambiar la
                  narrativa pública a través de campañas de medios y/o artes; 4.
                  Producir investigaciones e informes. Cuando se trata de la
                  lógica del programa, el 65% de los programas afirmaron “Si las
                  personas en puestos de toma de decisiones tienen información
                  más precisa, tomarán mejores decisiones", mientras que el 45%
                  dijo “Si las personas en puestos de toma de decisiones tienen
                  más empatía por las vidas e historias que serán afectadas por
                  sus decisiones, tomarán mejores decisiones”.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORTALEZA COMPARTIDA:
                </h3>
                <p>
                  Algunos temas muy importantes que están listos para ser
                  tratados dentro de litigios estratégicos atraviesan fronteras:
                  reforma de fianzas; barreras a la educación, el empleo y la
                  vivienda para personas anteriormente privadas de la libertad
                  (conocido en los Estados Unidos como el movimiento Ban the
                  Box, “Prohibir la casilla”); el tema de las vacaciones
                  judiciales, durante el cual los jueces con licencia o con
                  escasez atascan el sistema y llenan las cárceles durante
                  meses. La justicia, sostiene la INN, no se va de vacaciones,
                  nunca.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'directServices'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>SERVICIOS DIRECTOS</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUCHA COMPARTIDA:
                </h3>
                <p>
                  El 20% de los 81 programas de Servicios Directos de Inn
                  Partner identificó la “falta de programas de rehabilitación
                  existentes” como el principal problema o necesidad abordado
                  por sus programas. Sin embargo, en la base de datos, el 60% de
                  los programas en todo el mundo se incluyen en “Servicios
                  directos”{' '}
                  <a
                    style={{ color: 'black' }}
                    href="https://assets.ctfassets.net/fhwlk5jsnns3/3gIp85pR7uHBwUW9K23TOb/735ece0f7899f5725ef86d0998c31fe2/Comparative_tables_of_questionnaire_responses_for_selected_programs.pdf"
                  >
                    (ver Figura 1)
                  </a>
                  ; de ellos, la mayor concentración de programas cae dentro de
                  la categoría “Reingreso” (153 programas, 20% de todos los
                  programas) y “Otros servicios de rehabilitación” (79
                  programas, 10% de todos los programas). Solo el 14% de todos
                  los programas (111) corresponden a Alternativas al
                  encarcelamiento. Se puede concluir por lo tanto que con
                  demasiada frecuencia, los esfuerzos que salvan vidas al lidiar
                  con el problema del encarcelamiento a través de servicios
                  directos, especialmente con respecto al reingreso, eclipsan,
                  subsumen y absorben los recursos de los esfuerzos sistémicos y
                  generales. Y, sin embargo, estos esfuerzos de servicio directo
                  aún no son suficientes, ya que las organizaciones aún
                  identifican una escasez.
                </p>
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORTALEZA COMPARTIDA:
                </h3>
                <p>
                  Los múltiples componentes de los servicios directos se
                  superponen constantemente. Es prácticamente imposible
                  enfrentar solo una de las necesidades de una persona
                  involucrada en la justicia sin enfrentar simultáneamente
                  muchas otras necesidades. Por esta razón, es fundamental
                  contar con un equipo multidisciplinario u holístico. AJEM
                  (Líbano), por ejemplo, tiene abogados, trabajadores sociales y
                  psicólogos para tomar lo que describen como un “enfoque
                  multidimensional a través de la prevención, rehabilitación y
                  reinserción social”. El Red Hook Community Justice Center
                  (EE.UU.) es multidimensional y multi-jurisdiccional, y trabaja
                  no solo con la oficina del fiscal y los tribunales, sino
                  también con las oficinas de vivienda, educación, atención
                  médica y legal, tanto gubernamentales como no gubernamentales.
                </p>
              </div>
            </div>
            <hr />
            <div>
              <header className={classnames('text-center')}>
                <h2 style={{ color: 'black' }}>
                  ASSISTENCIA LEGAL O DE PAGO DE FIANZA
                </h2>
              </header>
              <div className={classnames('double', 'a', 'hr')} id={'legal'}>
                <div>
                  <h3 style={{ color: 'black', fontSize: '18px' }}>
                    LUCHA COMPARTIDA:
                  </h3>
                  <p>
                    En muchos países, especialmente en el Sur Global, la ley
                    actúa como una fuerza neocolonial invisible, que gobierna la
                    vida de las personas sin que ellas siquiera sepan cómo
                    funciona. Muchos sistemas legales alrededor del mundo que
                    tienen raíces coloniales han sido impuestos sobre un
                    contexto comunitario donde encajan increíblemente mal. La
                    sinécdoque perfecta para esto es la imagen de un juez
                    africano en un país como Ghana con un vestido formal y una
                    vieja peluca blanca británica en un sofocante mundo judicial
                    lejos de Inglaterra. Como nos recuerda MyJustice Myanmar,
                    “ley” significa “mentir” en el idioma karen. Organizaciones
                    como CHREAA (Malawi), MyJustice (Myanmar) y Advocaid (Sierra
                    Leona) están trabajando para que la ley sea accesible y
                    comprensible mediante el uso del teatro y el arte
                    comunitario para llegar a las masas, especialmente en las
                    zonas rurales.
                  </p>
                  <img
                    style={{
                      minHeight: '295px',
                      minWidth: '100%',
                      objectFit: 'cover',
                    }}
                    src="https://advocaidsl.org/wp-content/uploads/2018/12/IMG_0774.jpg"
                    alt="Placeholder"
                    width="360"
                    height="295"
                  />
                </div>
                <div>
                  <h3 style={{ color: 'black', fontSize: '18px' }}>
                    FORTALEZA COMPARTIDA:
                  </h3>
                  <p>
                    A nivel mundial, las organizaciones están empleando varios
                    enfoques comunes para ayudar a y reducir la población
                    privadas de la libertad, especialmente las población en
                    prisión preventiva. Dichos enfoques incluyen: 1. Brindar
                    asistencia legal lo antes posible en el proceso (en
                    estaciones de policía en India a través de CHRI, por
                    ejemplo) junto con varios tipos de servicios adicionales
                    (Advocaid, Sierra Leona; Brooklyn Defender Services, EE.
                    UU.); 2. Desarrollar evaluaciones de riesgo culturalmente
                    sensibles para sacar a las personas de la prisión preventiva
                    (JJAI, México); 3. Uso de tribunales móviles y capacitación
                    legal en las cárceles para que las personas ya no estén
                    detenidas (programa Justicia para Todos, Ghana); 4. Generar
                    fondos de fianza y abogar por la reforma de la fianza (The
                    Bail Project, EE. UU.); 5. Desarrollar y acreditar
                    abogados/as y asistentes legales privados/as de la libertad
                    para eliminar los atrasos en el tratamiento de los casos
                    (Proyecto de Prisiones Africanas, Kenia y Uganda); 6.
                    Proporcionar asistencia legal, así como educación
                    legal/habilidades de empoderamiento (CHREAA, Malawi).
                  </p>
                  <h3 style={{ color: 'black', fontSize: '18px' }}>
                    FORTALEZA COMPARTIDA:
                  </h3>
                  <p>
                    Las organizaciones que se acercan de manera holística a la
                    asistencia legal, como Brooklyn Defender Services (EE. UU.)
                    y Advocaid (Sierra Leona), ofrecen múltiples tipos de
                    servicios sociales a sus clientes, reconociendo que aquellos
                    atrapados en el sistema de justicia penal a menudo tienen
                    necesidades que se extienden más allá de la asistencia
                    legal.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div id={'reentry'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>REINSERCIÓN</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUCHA COMPARTIDA:
                </h3>
                <p>
                  "Reingreso / Reintegración” es la categoría de programa más
                  grande tanto en la lista de inmersiones profundas como en la
                  lista de la base de datos, lo que sugiere la gravedad del
                  problema a nivel mundial y la cantidad de recursos que se
                  gastan para abordarlo. El 34% de los programas de Servicios
                  directos de Inn Partner de todas las variedades afirmaron que
                  su objetivo era “Mejorar las condiciones, los recursos y las
                  perspectivas de reintegración después de la liberación” (un
                  27% seleccionó “Mejorar el bienestar y las habilidades de las
                  personas privadas de la libertad en la cárcel”).
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUCHA COMPARTIDA:
                </h3>
                <p>
                  Las barreras profundas para el empleo, la vivienda y la
                  educación de las y los ciudadanos que regresan son realidades
                  mundiales. Sin embargo, los esfuerzos para eliminar tales
                  barreras, tanto legales (a través de litigios estratégicos y
                  otros trabajos de defensa) como culturales (a través del
                  cambio de la narrativa sobre las personas anteriormente
                  privadas de la libertad y la aceptación de la comunidad), son
                  escasas en casi todas partes, excepto en los Estados Unidos,
                  donde se conocen colectivamente como el “Movimiento Ban the
                  Box” (refiriéndose a la casilla que corresponde a la pregunta
                  “¿tiene antecedentes penales?”, la cual debe ser marcada al
                  realizar una aplicación). Incluso en países como los Países
                  Bajos, donde no existen grandes barreras, las y los ciudadanos
                  que regresan operan bajo la idea errónea de que sí las hay y,
                  por lo tanto, detestan aprovechar las oportunidades. Las
                  barreras informales, como el estigma y la vergüenza,
                  prevalecen y son difíciles de cuantificar.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUCHA COMPARTIDA:
                </h3>
                <p>
                  A excepción de Singapur, los gobiernos carecen de unidades de
                  reinserción robustas, tanto locales como nacionales, y bases
                  de datos de reinserción laborales (similar a la de Singapur,
                  que tiene 5520 empleadores registrados). Esta es un campo
                  donde hay una gran necesidad y un potencial para crecer.
                </p>
                <img
                  style={{ minHeight: '625px', objectFit: 'cover' }}
                  className={'desktop-only'}
                  src="https://www.score.gov.sg/images/default-source/module/home-content/63840343-ee9f-4340-9b5d-c3215518b279.jpg"
                  alt="Placeholder"
                  width="400"
                  height="625"
                />
                <img
                  className={'desktop-hide'}
                  style={{
                    minHeight: '295px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="https://www.score.gov.sg/images/default-source/module/home-content/63840343-ee9f-4340-9b5d-c3215518b279.jpg"
                  alt="Placeholder"
                  width="360"
                  height="295"
                />
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORTALEZA COMPARTIDA:
                </h3>
                <p>
                  Hay dos cosas que son especialmente fundamentales para el
                  éxito de los programas de reinserción. Primero, un esfuerzo
                  continuo, que comienza mientras una persona está privada de la
                  libertad y continúa cuando esa persona es liberada (en
                  Singapur se denomina “cuidado minucioso”, y sus programas
                  están comprometidos con ello). Segundo, un enfoque holístico a
                  los desafíos universales de la reinserción: búsqueda de
                  vivienda, obtención de empleo, obtención de apoyo emocional y
                  postraumático. Estos factores distinguen la categoría de
                  “Reinserción” de las otras categorías de “Servicios directos”
                  en las cárceles, que se centran solamente en trabajar dentro
                  de las cárceles. La INN tiene la convicción que todo el
                  trabajo que comienza dentro de las cárceles debe continuar
                  fuera de las cárceles y se debe trabajar con las personas
                  cuando son liberadas.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORTALEZA COMPARTIDA:
                </h3>
                <p>
                  El elemento clave de cualquier programa de reinserción son las
                  personas. Ninguna aplicación o algoritmo puede sustituir a los
                  seres humanos que trabajan en estrecha colaboración para
                  apoyar a las personas que regresan a casa después de la
                  prisión. SCORE (Singapur) tiene un vigoroso equipo móvil de
                  capacitación laboral, que visita y apoya a sus clientes
                  mientras se están adaptando a la vida laboral (probablemente
                  tienen un papel en el hecho que las y los ciudadanos que se
                  reinsertan tienen una tasa de retención laboral del 81%). El
                  Community Restorative Center NSW (Australia) utiliza un
                  “modelo intensivo de gestión de casos”, que implica, como lo
                  expresó su personal, “no ser tan clínico” para interactuar
                  constantemente con las y los ciudadanos que se reintegran y
                  mantener contacto con ellos y ellas por medido de llamadas
                  telefónicas. Las viviendas posteriores a la prisión
                  administradas por Exodus (Países Bajos) tienen mentores que
                  están disponibles las 24 horas del día para amistad y apoyo.
                  El 28.4% de los programas de Inn Partner identificaron la
                  “dedicación y pasión del personal” como el ingrediente clave
                  más popular para el éxito del programa. En las conversaciones
                  de la INN con los clientes que regresan a casa de la prisión,
                  se subrayó una y otra vez la necesidad de asistencia
                  personalizada.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORTALEZA COMPARTIDA:
                </h3>
                <p>
                  Para muchas personas, la reintegración es un proceso largo con
                  muchos altibajos. Esto significa que el soporte debe
                  extenderse más allá de varios meses o incluso un año. Exodus
                  (Países Bajos) habla de una especie de programa de cuidado
                  posterior para aquellos que se mudaron de las viviendas del
                  programa y aun así desean consultar con mentores y aprovechar
                  el apoyo y las oportunidades.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORTALEZA COMPARTIDA:
                </h3>
                <p>
                  La vivienda es un desafío universal para las personas que
                  regresan a casa de la cárcel, por lo que es fundamental ser
                  innovadores para generar oportunidades de vivienda,
                  especialmente en ciudades con mercados de viviendas caros y
                  saturados. Pension Skejby (Dinamarca) dirige un espacio que
                  alberga tanto a estudiantes como a ciudadanos y ciudadanas que
                  se están reintegrando, que comparten áreas comunes y
                  construyen comunidad juntos y juntas. El Proyecto Homecoming
                  (Estados Unidos) une a las personas que tienen espacio en sus
                  hogares con las personas que regresan de la prisión.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'education'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>EDUCACIÓN EN LAS CÁRCELES</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUCHA COMPARTIDA:
                </h3>
                <p>
                  Los programas de educación en las cárceles sólidos son de
                  vital importancia. Los niveles de educación y la seguridad de
                  la comunidad siempre están correlacionados, algo que el
                  sentido común y la investigación respaldan. Sin embargo, tales
                  programas son muy escasos incluso en lugares donde están
                  generalizados, como en Europa.{' '}
                  <a
                    style={{ color: 'black' }}
                    href="https://antoniocasella.eu/nume/Hawley_UE_education_may13.pdf"
                  >
                    {' '}
                    Una encuesta del 2013 de sobre educación y capacitación en
                    prisiones en Europa
                  </a>{' '}
                  encontró que en la mayoría de los Estados de la Unión Europea,
                  la participación en educación y capacitación en prisiones era
                  inferior al 25%. Las barreras a la participación incluyeron
                  experiencias de educación previas que resultaron ser
                  negativas; el hecho de que había mayores incentivos para
                  participar en el trabajo penitenciario que en los programas
                  educativos; y un número limitado de cupos en estos programas.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORTALEZA COMPARTIDA:
                </h3>
                <p>
                  Toda clase de niveles de educación en las cárceles es de vital
                  importancia, especialmente con respecto a la alfabetización y
                  la aritmética. Pero los programas de educación superior no
                  deben pasarse por alto. No solo elevan el estándar en términos
                  de las ideas del público en general sobre lo que las personas
                  en prisión son capaces de hacer, sino que son una inversión en
                  el liderazgo futuro y, nuevamente al establecer el alto
                  estándar, un camino hacia una oportunidad genuina, en lugar de
                  simplemente sobrevivir. La creación de empleo de bajo nivel o
                  el empleo asalariado no pueden abordar la desigualdad
                  sistémica, pero la inversión en educación de calidad,
                  oportunidades vigorosas y liderazgo visionario pueden ser una
                  vía para avanzar hacia un cambio generativo.
                </p>
                <img
                  style={{
                    minHeight: '295px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="https://images.squarespace-cdn.com/content/57cd909c197aea06e48be99b/1537440132261-JEW860FOZYJPQ5GB8NNL/IMG_2054.JPG?format=750w&content-type=image%2Fjpeg"
                  alt="Placeholder"
                  width="360"
                  height="295"
                />
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORTALEZA COMPARTIDA:
                </h3>
                <p>
                  En encuestas a clientes de tres programas de educación en las
                  cárceles de nivel universitario en tres países diferentes en
                  tres continentes diferentes (Kenia, Estados Unidos e Italia),
                  las y los estudiantes privados de la libertad elogiaron sus
                  programas en términos similares. Hicieron hincapié en que la
                  educación superior los fortaleció, los hizo ver el futuro de
                  manera mucho más positiva y creer en su propio valor
                  (“Continuar con mi educación ha confirmado mi valor y mi
                  creencia de que hay vida después del encarcelamiento”, dijo un
                  NJ-Step ( EE. UU.); un estudiante de Articolo3 (Italia)
                  describió el valor de la educación universitaria mientras
                  estaba privado de la libertad lo describió de la siguiente
                  manera “Confiar en mí mismo, la posibilidad de tener un
                  futuro, ver mi vida en colores en lugar de en blanco y negro”;
                  un estudiante de Proyecto de Prisiones Africanas (Uganda,
                  Kenia) habló de su “cambio de actitud hacia la vida y las
                  percepciones también, el empoderamiento mediante la
                  adquisición de conocimiento... respeto para mí mismo y por los
                  demás, así como mi dignidad mejorada”; otro estudiante de APP
                  señaló que “el programa ha demostrado que los prisioneros
                  tienen soluciones para sus propios problemas, lo único que
                  necesitan es apoyo”). Tanto los estudiantes de APP (Uganda,
                  Kenia) como NJ Step (EE. UU.) declararon que los programas de
                  educación también brindan más estabilidad a la prisión en
                  general.
                </p>

                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORTALEZA COMPARTIDA:
                </h3>
                <p>
                  La INN cree que las universidades tienen un papel clave a
                  desempeñar en la construcción de comunidades más seguras, y
                  las formas innovadoras de asociaciones entre la prisión y la
                  universidad están llenas de posibilidades. Después de todo,
                  las universidades tienen una fuerza laboral que se está
                  capacitando (estudiantes docentes, trabajadores sociales y
                  más) que podrían beneficiarse de la exposición a la población
                  privada de la libertad, que a su vez se beneficia de la
                  creación de redes con jóvenes y futuros líderes que están
                  construyendo sus carreras. En términos generales, las
                  universidades también son entidades bastante estables sin
                  vínculos políticos explícitos, algo raro en países que
                  enfrentan inestabilidad política y económica. Por lo tanto,
                  son muy beneficiosas cuando se trata de construir y mantener
                  programas a largo plazo en las cárceles.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'jobDevelopment'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>
                DESARROLLO DE EMPLEO EN LAS CÁRCELES
              </h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUCHA COMPARTIDA:
                </h3>
                <p>
                  Un{' '}
                  <a
                    style={{ color: 'black' }}
                    href="https://prisonpolicy.org/reports/outofwork.html"
                  >
                    estudio de mostró que en el 2018,
                  </a>{' '}
                  as personas anteriormente privadas de la libertad estaban
                  desempleadas a una tasa de más del 27 por ciento, más alta que
                  la tasa de desempleo total de los EE. UU. durante cualquier
                  período histórico y casi cinco veces más alta que la tasa de
                  desempleo para la población general. Aunque no existen
                  estudios similares a nivel mundial, los socios de la INN
                  señalan constantemente la falta de empleos
                </p>
                <img
                  style={{
                    minHeight: '475px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="https://static.wixstatic.com/media/b8d0a9_8aae039800624e0b865453feb142b110~mv2_d_4128_2322_s_2.jpg/v1/fill/w_899,h_300,al_c,q_80,usm_0.66_1.00_0.01/b8d0a9_8aae039800624e0b865453feb142b110~mv2_d_4128_2322_s_2.webp"
                  alt="Placeholder"
                  width="360"
                  height="400"
                />
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORTALEZA COMPARTIDA:
                </h3>
                <p>
                  Los modelos cooperativos (es decir, tanto Coostafe como
                  Humanitas360 en Brasil) son especialmente prometedores, ya que
                  les dan a las personas privadas de la libertad una
                  participación y propiedad sobre su propio trabajo, impactan
                  sus vidas al ser liberados y resisten el paradigma del trabajo
                  en prisión (y el trabajo esclavo) que tiene una historia
                  global larga y opresiva.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORTALEZA COMPARTIDA:
                </h3>
                <p>
                  Las encuestas a clientes revelan que muchos programas (es
                  decir, Nafisika en Kenia) tienen la intención de hacer una
                  cosa (Nafisika: dar habilidades de trabajo a una persona),
                  pero finalmente terminan haciendo varias cosas que se
                  extienden mucho más allá del ámbito de las habilidades
                  vocacionales: fomentar la confianza y el compromiso de la
                  comunidad , encendiendo un interés en la educación, agudizando
                  las relaciones interpersonales y generando empatía.
                </p>

                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORTALEZA COMPARTIDA:
                </h3>
                <p>
                  Los programas innovadores tienen la intención de capacitar a
                  sus clientes en trabajos de alta demanda, tales como
                  horticultura (Hlumelilisa, Sudáfrica), codificación (The Last
                  Mile, Estados Unidos) y espíritu empresarial (Nafisika,
                  Kenia). También, al igual que los programas de reinserción
                  fuertes, emplean un modelo de canalización, ayudando a sus
                  clientes con la búsqueda de trabajo al momento de la
                  liberación.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'staffTraining'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>
                CAPACITACIÓN DEL PERSONAL PENITENCIARIO
              </h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUCHA COMPARTIDA:
                </h3>
                <p>
                  El hecho de que esta fuera la segunda categoría más baja en la
                  lista de la Base de datos de servicios directos, con solo 14
                  programas globales centrados en el personal penitenciario, es
                  profundamente preocupante. Así como una escuela es tan fuerte
                  como sus maestros, un sistema de penitenciario o de justicia
                  es tan fuerte como su personal. Existe un creciente interés a
                  nivel mundial en las condiciones carcelarias y especialmente
                  en la arquitectura, pero sin un cambio cultural con respecto
                  al personal, incluido el apoyo del personal, la capacitación y
                  el reclutamiento de personas de mentalidad progresiva para el
                  trabajo, tales cambios corren el riesgo de ser meramente
                  cosméticos.
                </p>
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORTALEZA COMPARTIDA:
                </h3>
                <p>
                  Una cultura que valora a los oficiales penitenciarios atraerá
                  fuertes candidatos a la profesión. La escuela de formación de
                  Noruega y Unblocked Graduates (Reino Unido) lo reconocen, el
                  primero paga a sus oficiales de manera competitiva y crea un
                  ambiente y una cultura amenos en el trabajo; este último
                  trabaja para cambiar la percepción del trabajo en contextos de
                  reclutamiento universitario y crea un programa de apoyo de
                  becas para sus reclutas.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'rehabServices'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>
                OTROS SERVICIOS PARA LA REHABILITACIÓN EN LAS CÁRCELES
              </h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <img
                  style={{
                    minHeight: '275px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="http://www.insightprisonproject.org/uploads/1/1/6/0/11602085/1345745274.jpg"
                  alt="Placeholder"
                  width="360"
                  height="275"
                />
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORTALEZA COMPARTIDA:
                </h3>
                <p>
                  Las encuestas a clientes de organizaciones en cuatro países y
                  en tres continentes diferentes (Líbano, El Salvador, Bélgica,
                  Reino Unido) revelan beneficios comunes de diversos esfuerzos
                  de rehabilitación en las cárceles, ya sea a través del teatro,
                  la resolución de conflictos o la escritura creativa. La
                  mayoría de las veces se citó la confianza y el vínculo
                  comunitario que estos programas crean: “Comunidad, construir
                  relaciones con los demás. Me he vuelto más humanitario; He
                  comenzado a ayudar a otros sin esperar ningún beneficio”(AJEM,
                  Líbano); “El programa nos permite pensar juntos como un grupo
                  en el que todos dan su opinión. Eso nos hace convivir como
                  amigos y autores”(Contextos, El Salvador); “Hasta que comencé
                  con el programa me sentía solo, no tenía a nadie con quien
                  hablar... Ahora sé que tengo personas con las que puedo
                  conocer y hablar. No me siento tan solo” (De Rode Antraciet,
                  Bélgica); “He visto a un grupo de personas privadas de la
                  libertad sin nada en común que han creado vínculos y su propia
                  versión de una unidad familiar, compartiendo cuidado y
                  compasión de una manera hermosa” (LEAP, Reino Unido).
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'health'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>
                SALUD Y SEGURIDAD EN LAS CÁRCELES
              </h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUCHA COMPARTIDA:
                </h3>
                <p>
                  En la base de datos, solo el 1% de los programas se incluyen
                  en esta categoría, a pesar de la alarmante cantidad de
                  epidemias de VIH/ SIDA y tuberculosis en las cárceles,
                  especialmente en África y el sudeste asiático.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUCHA COMPARTIDA:
                </h3>
                <p>
                  No se puede exagerar el impacto de la guerra contra las drogas
                  en las poblaciones penitenciarias de todo el mundo. Si bien la
                  legalización de la marihuana y la idea de tratar el abuso de
                  sustancias como una crisis de salud pública en lugar de una
                  crisis de justicia penal, incluidos los enfoques de reducción
                  de daños, están ganando apoyo mundial poco a poco, este
                  problema necesita mucho más impulso para tener un impacto
                  tangible. Esta es un área de crecimiento en todo el mundo, ya
                  que los avances serios en la política de drogas podrían
                  reducir las poblaciones carcelarias en proporciones
                  dramáticas.
                </p>
              </div>
              <div>
                <img
                  style={{
                    minHeight: '475px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="https://justdetention.org/wp-content/uploads/2015/10/joke_cropped_hi_res-1024x741.jpg"
                  alt="Placeholder"
                  width="360"
                  height="400"
                />
              </div>
            </div>
          </div>
          <hr />
          <div id={'innovative'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>
                ALTERNATIVAS INOVADORAS A LAS CÁRCELES TRADICIONALES
              </h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <p>
                  <span className="strong">NOTA: </span>IEn la base de datos, la
                  mayor concentración de estas alternativas innovadoras se
                  encuentra en Europa.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUCHA COMPARTIDA:
                </h3>
                <p>
                  Las instalaciones o alas de “prisiones modelo”, la o las
                  prisiones consideradas excepciones progresivas a la norma de
                  otro modo poco progresiva de un país, son valiosas como
                  indicación valiente de lo que podría y debería ser. Pero son
                  peligrosas en la medida en que siguen siendo una excusa para
                  un cambio generalizado, apuntalando el sistema y
                  convirtiéndose en un obstáculo para la replicación y el cambio
                  sistémico. El caso del Centro de detención a pequeña escala de
                  Amsterdam West, inicialmente un modelo experimental de
                  detención abierta para personas jóvenes privadas de la
                  libertad es un ejemplo de cómo, como lo expresó su fundador,
                  “un pequeño centro impactó a un país entero”: a partir de 2019
                  esta instalación está programada para ser replicada en todo el
                  país, lo que llevaría al cierre de múltiples centros de
                  detención juvenil y a un cambio sistémico radical.
                </p>
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORTALEZA COMPARTIDA:
                </h3>
                <p>
                  En respuesta a la pregunta “¿Qué diferencia a su instalación
                  instalaciones de las cárceles tradicionales?” Tres de los
                  cinco programas respondieron “principios o filosofía del
                  castigo”; dos de los cinco también respondieron “Cooperación
                  con comunidades e individuos locales” y “mayores niveles de
                  libertad”.
                </p>
                <img
                  style={{
                    minHeight: '375px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="https://pulitzercenter.org/sites/default/files/styles/node_images_768x510/public/11-17-14/unusual-prison-11.jpg?itok=BrnW17ps"
                  alt="Placeholder"
                  width="360"
                  height="375"
                />
              </div>
            </div>
          </div>
        </article>
        <div id={'bigPicture'}>
          <article className={classnames('module-featured', 'box')}>
            <h2>APRENDIZAJES GENERALES DE LA PLATAFORMA</h2>
            <p>
              <span
                className={classnames('strong', 'text-uppercase')}
                style={{ fontStyle: 'italic' }}
              >
                Kua tawhiti ke to haerenga mai, kia kore e haere tonu; He tino
                nui rawa o mahi, kia kore e mahi nui tonu. <br /> <br /> Has
                llegado demasiado lejos para no ir más lejos; has hecho
                demasiado para no hacer más.
              </span>{' '}
              <br /> -Sir James Henare (Ngati Hine, 1989)
            </p>
            <figure className={'overlay-d'}>
              <img
                src="https://placehold.it/1440x710"
                alt="Placeholder"
                width="1440"
                height="710"
              />
            </figure>
          </article>
          <article className={'mn'}>
            <div className={classnames('double', 'a', 'tablet-compact')}>
              <div className={'m0'}>
                <ul>
                  <p>
                    <span className="strong">NOTA: </span>as reflexiones
                    generales sobre la plataforma y el estado del trabajo
                    penitenciario global generalmente se inspiran en los datos
                    recopilados en los cuestionarios, así como en las visitas a
                    campo del Dr. Dreisinger y en entrevistas formales e
                    informales con organizaciones y clientes. Los cuadros y las
                    figuras comparativas de las respuestas seleccionadas al
                    cuestionario obtenidas de los programas de Inn Partner se
                    pueden encontrar{' '}
                    <a
                      style={{ color: 'black' }}
                      href="https://assets.ctfassets.net/fhwlk5jsnns3/3gIp85pR7uHBwUW9K23TOb/735ece0f7899f5725ef86d0998c31fe2/Comparative_tables_of_questionnaire_responses_for_selected_programs.pdf"
                    >
                      aquí
                    </a>
                    ; Se puede encontrar una bibliografía relevante{' '}
                    <a
                      style={{ color: 'black' }}
                      href="https://assets.ctfassets.net/fhwlk5jsnns3/4qE4rtxCNWUOUdpGizTuoG/a029e92dc23a6c5ad28ba03ed67bc796/GlobalAssessment_Bibilio.docx"
                    >
                      aquí
                    </a>
                    .
                  </p>
                  <li>
                    <span className="strong">PENSAR CREATIVAMENTE: </span>
                    No solo es posible ser global y local al mismo tiempo, es
                    muy beneficioso serlo. Cuando uno sale de su propio sistema
                    para ver los problemas y las soluciones, la mente comienza a
                    pensar radicalmente sobre lo que es posible, más allá de lo
                    que es. Con demasiada frecuencia, incluso los trabajadores
                    de la justicia más progresistas son estrangulados por lo que
                    es, en lugar de inspirarse por lo que podría ser y liberados
                    por lo que debería ser. La trayectoria histórica de la
                    práctica penitenciaria global hasta el momento ha
                    involucrado la exportación estadounidense de su modelo de
                    prisión, un experimento único en la historia, al mundo,
                    primero en el siglo XIX a través del colonialismo y luego en
                    el siglo XX a través de la globalización. Esto se puede
                    deshacer interrumpiendo el flujo de ideas: se pueden
                    compartir enfoques progresivos estadounidenses y no
                    estadounidenses, y el proceso de aprendizaje puede y debe
                    operar en múltiples direcciones.
                  </li>
                  <li>
                    <span className="strong">
                      REPLICACIÓN DE PROGRAMAS PROMETEDORES:{' '}
                    </span>
                    Esto ya está sucediendo en espacios globales. La prisión de
                    Bastoy de Noruega se ha replicado en la República Checa, por
                    ejemplo, y el modelo de mediación de Madaripur de Bangladesh
                    se ha replicado en Malawi. Pero este proceso no es una
                    ciencia exacta y no existe la justicia que se pueda cortar y
                    pegar. Por lo tanto, es mejor hablar en términos de
                    inspiración, no de replicación. Los programas deben tener en
                    cuenta las especificidades culturales incluso cuando
                    reconocemos la universalidad de la pobreza, el racismo y la
                    injusticia en el núcleo de los sistemas penitenciarios.{' '}
                    <a
                      style={{ color: 'black' }}
                      href="https://theguardian.com/social-enterprise-network/2012/nov/06/social-franchising-how-what-benefits"
                    >
                      El modelo de franquicia social
                    </a>{' '}
                    es relevante aquí.
                  </li>
                  <li>
                    <span className="strong">MÁS QUE GESTOS VACÍOS: </span>
                    Los gobiernos de todo el mundo han cambiado el “Departamento
                    de Prisiones” por el “Departamento de Correcciones”,
                    haciendo una demostración destacada de este cambio en la
                    terminología, pero con demasiada frecuencia esto es un gesto
                    vacío. Los gobiernos todavía invierten poco en
                    “correcciones” y, en cambio, reducen los presupuestos de las
                    cárceles. Debido a la falta de apoyo público para los
                    programas relacionados con las cárceles y al discurso
                    mayoritariamente punitivo (mano dura contra el crimen en
                    lugar de tratamiento inteligente del crimen), los gobiernos
                    enfrentan poca presión para gastar más en correcciones y en
                    su lugar se centran en temas más populares que producen
                    resultados inmediatos (y por lo tanto les ganan la
                    reelección). Corresponde a los gobiernos dictar y, a falta
                    de un término mejor, comercializar la justicia inteligente
                    para el público, no al revés; sin embargo, esta no es la
                    realidad en la mayor parte del mundo.
                  </li>
                  <li>
                    <span className="strong">
                      FALTA DE RECURSOS Y SOSTENIBILIDAD:{' '}
                    </span>
                    El resultado del sombrío escenario anterior es devastador
                    para el campo de la justicia. De los programas de Inn
                    Partner, el desafío más frecuentemente identificado fue la
                    falta de fondos, ya sea en general o de varios años (el 69%
                    lo identificó). Operando con presupuestos mínimos, las
                    organizaciones deben depender del trabajo voluntario, lo
                    cual no es sostenible. Las organizaciones también tienen la
                    incapacidad de pensar de manera global sobre su trabajo
                    porque la falta de fondos las deja luchando para sobrevivir
                    en el aquí y ahora. Esto, a su vez, dificulta la capacidad
                    de una organización para ser audaz, innovadora y
                    autocrítica; También da como resultado la falta de
                    solidaridad y, a veces, conciencia por parte de las
                    organizaciones que realizan trabajos relacionados, incluso
                    en sus propios países, una realidad intensificada por la
                    competencia por fondos mínimos.
                  </li>
                  <li>
                    <span className="strong">CAMBIO DE LA NARRATIVA: </span>
                    Para abordar la crisis de financiación, es clave cambiar la
                    narrativa pública sobre las cárceles y las personas privadas
                    de la libertad, pasando de un discurso de venganza a uno de
                    reparación. Cuando el público en general se da cuenta de que
                    las cárceles no construyen comunidades seguras y que la
                    mayoría de las personas privadas de la libertad necesitan
                    asistencia, no daños y castigos, cuando, en otras palabras,
                    la prisión se convierte en una causa popular y no, como es
                    el caso en casi todo el mundo, un tema tabú que invoca
                    reacciones punitivas, ese público exigirá un cambio. Los
                    políticos deben prestar atención e invertir su dinero en los
                    temas que discuten, y los financiadores deben volverse más
                    susceptibles a apoyar el trabajo relacionado con la prisión.
                    Este cambio en la narrativa ya ha sucedido en los Estados
                    Unidos. Hace menos de una década, la reforma penitenciaria
                    era una causa muy impopular; hoy, incluso la mayoría de las
                    celebridades la convierte en su plataforma pública. El papel
                    de las artes (películas, instalaciones artísticas, libros)
                    es vital aquí, ya que son capaces de cambiar los corazones y
                    las mentes (como lo hicieron obras como el documental The{' '}
                    <span style={{ fontStyle: 'italic' }}>13th</span> nominado
                    al Oscar y el libro más vendido en los Estados Unidos{' '}
                    <span style={{ fontStyle: 'italic' }}>
                      The New Jim Crow.
                    </span>{' '}
                    Casi todos los socios de la INN expresaron que deseaban que
                    el presupuesto y la banda ancha estuvieran orientados a las
                    comunicaciones y la comunicación, difundiendo su trabajo y
                    cambiando el discurso público. También expresaron su
                    frustración con la cobertura sensacionalista de los medios
                    de comunicación sobre delitos y asuntos relacionados con las
                    cárceles, lo que mantiene al público en general en un modo
                    punitivo y temeroso y obstaculiza significativamente su
                    trabajo. La fuerza de la opinión pública es una de las
                    razones por las cuales la práctica basada en la evidencia,
                    aunque ciertamente tiene un gran valor, tiene sus
                    limitaciones: incluso cuando los gobiernos tienen datos y
                    saben muy bien qué “funciona”, es posible que no actúen
                    teniendo en cuenta esos datos si el público votante está
                    totalmente en contra de ello. En todo el mundo, los
                    programas sólidos se convierten en peones en la guerra de
                    los partidos políticos, en detrimento de la seguridad, la
                    paz y la justicia de la comunidad.
                  </li>
                  <li>
                    <span className="strong">
                      REESCRIBIR NARRATIVAS COMUNITARIAS:{' '}
                    </span>
                    El cambio narrativo y la narración de historias también son
                    fundamentales cuando se trata de comunidades que creen e
                    invierten en sí mismas. Cuando una comunidad acepta una
                    identidad como un “gueto” plagado de crímenes, se siente
                    cómoda en esa narrativa, y se compromete con ella. Pero
                    cuando se ve a sí misma como una comunidad empoderada
                    conocida por la paz y la justicia, trabaja con avidez para
                    mantener esa identidad positiva. El trabajo de JustReinvest
                    (Australia) en Bourke es muy revelador en este sentido. A
                    través del trabajo de reinversión de justicia, una comunidad
                    conocida como una de las más castigadas por el crimen en
                    Australia remodeló su estructura social y, al hacerlo,
                    reescribió y revisó su propia narrativa; esta nueva
                    narrativa, de la comunidad pacífica en lugar de “peligrosa”,
                    se autoperpetúa. La narrativa que tenemos sobre nuestra
                    identidad tiene mucho poder sobre nosotros y nosotras.
                  </li>
                  <li>
                    <span className="strong">FINANCIACIÓN PÚBLICA: </span>
                    El objetivo de los programas innovadores debe ser la
                    sistematización. Después de todo, es trabajo del gobierno
                    generar comunidades justas y pagar los programas y las
                    estructuras necesarias para producir eso. Sin embargo, los
                    números de la Inn Partner con respecto a las fuentes de
                    financiación reflejan que dicha sistematización está
                    ocurriendo de manera desigual en todo el mundo. Algo más de
                    la mitad (54% del total de los programas de Inn Partner) son
                    financiados principalmente por fuentes no gubernamentales,
                    aunque los Servicios Directos (57%) y las Alternativas al
                    Encarcelamiento (63%) todavía son predominantemente
                    financiados por el gobierno en general{' '}
                    <a
                      style={{ color: 'black' }}
                      href="https://assets.ctfassets.net/fhwlk5jsnns3/3gIp85pR7uHBwUW9K23TOb/735ece0f7899f5725ef86d0998c31fe2/Comparative_tables_of_questionnaire_responses_for_selected_programs.pdf"
                    >
                      (Cuadro 3)
                    </a>
                    . Sin embargo, de los programas predominantemente
                    financiados por el gobierno, menos de la mitad provienen de
                    asignaciones presupuestarias y el resto de subvenciones del
                    gobierno. El financiamiento gubernamental para los programas
                    relacionados con las cárceles depende en gran medida de la
                    región. En los programas de Norte América, la financiación
                    es más equitativa entre fuentes gubernamentales y no
                    gubernamentales; en Europa y Oceanía, los programas fueron
                    financiados predominantemente (más del 70%) por el gobierno;
                    entre el 70 y el 100% de los programas en Asia, África y
                    América Latina fueron financiados por fuentes no
                    gubernamentales{' '}
                    <a
                      style={{ color: 'black' }}
                      href="https://assets.ctfassets.net/fhwlk5jsnns3/3gIp85pR7uHBwUW9K23TOb/735ece0f7899f5725ef86d0998c31fe2/Comparative_tables_of_questionnaire_responses_for_selected_programs.pdf"
                    >
                      (Cuadro 3)
                    </a>
                    . Donde hay financiamiento del gobierno, muchas
                    organizaciones expresaron su frustración con los estrictos
                    requisitos en términos de números y entregas, lo cual
                    dijeron que les impedía hacer un trabajo de calidad en
                    nombre de producir una cantidad específica de trabajo.
                  </li>
                  <li>
                    <span className="strong">
                      FUENTES DE FINANCIACIÓN CREATIVA:{' '}
                    </span>
                    De los programas predominantemente no financiados por el
                    gobierno en la plataforma INN Partner, los donantes
                    internacionales conforman la categoría más alta y el sector
                    privado la más baja. Las fuentes creativas de financiación,
                    como la inversión de impacto y los modelos de empresas
                    sociales, solo fueron indicadas como parte de la receta de
                    financiación por dos organizaciones. Dada la creciente
                    relevancia de estos modelos de financiación autogeneradores
                    que cambian los sistemas, pensar en ellos en un espacio de
                    justicia debería estar mucho más extendido y debería
                    promoverse a través de talleres y reuniones dinámicas y
                    accesibles.
                  </li>
                  <li>
                    <span className="strong">COLABORACIÓN: </span>
                    El 43.2% de los programas de Inn Partner mencionaron la
                    “colaboración con socios (externos)” como uno de sus
                    ingredientes clave para el éxito; tales socios incluyen
                    políticos locales, el poder judicial, funcionarios
                    gubernamentales y organizaciones comunitarias{' '}
                    <a
                      style={{ color: 'black' }}
                      href="https://assets.ctfassets.net/fhwlk5jsnns3/3gIp85pR7uHBwUW9K23TOb/735ece0f7899f5725ef86d0998c31fe2/Comparative_tables_of_questionnaire_responses_for_selected_programs.pdf"
                    >
                      (ver Figura 3)
                    </a>
                    . Una vez más, como se indicó anteriormente, los
                    trabajadores de justicia de todo el mundo generalmente
                    operan en silos no colaborativos. Los gobiernos deben apoyar
                    las reuniones y mesas redondas que reúnen a varios sectores
                    involucrados en este trabajo, y crear entidades unificadoras
                    similares a la Red CARE de Singapur, que fomenta las
                    sinergias entre ellos.
                  </li>

                  <li>
                    <span className="strong">OTROS DESAFÍOS: </span>
                    Otros desafíos importantes que los programas de Inn Partner
                    mencionaron con mayor frecuencia fueron: falta de espacio o
                    infraestructura para actividades (entre alternativas
                    innovadoras a las cárceles tradicionales); Falta de apoyo
                    político o gubernamental (para alternativas al
                    encarcelamiento); e Inestabilidad política o rotación (para
                    los programas de Política / Defensa).
                  </li>
                  <li>
                    <span className="strong">
                      INNOVACIÓN SENCILLA PERO EFECTIVA:{' '}
                    </span>
                    El trabajo de justicia ciertamente exige una innovación
                    radical. Pero al mismo tiempo, reducir y apoyar a las
                    poblaciones carcelarias a menudo no exige nuevas ideas
                    extremas, sino el compromiso y la inversión para implementar
                    enfoques simples pero impactantes, como tribunales móviles,
                    programas de medidas alternas, búsqueda de trabajo mientras
                    las personas aún están privadas de la libertad en lugar de
                    después, soporte intensivo posterior a la liberación, y más.
                    También en muchos países, los programas gubernamentales de
                    reforma penitenciaria funcionan en teoría, pero no en la
                    práctica: el lenguaje y el modelo están ahí pero no se
                    implementan, principalmente debido a la falta de recursos y,
                    por lo tanto, a la falta de un esfuerzo eficiente.
                  </li>
                </ul>
              </div>
              <div>
                <ul>
                  <li>
                    <span className="strong">LO PEQUEÑO NO ES MALO: </span>
                    La mejor justicia es individualizada, lo que JustSpeak en
                    Nueva Zelanda llama “a medida”, por lo tanto, a menudo entre
                    más pequeño mejor, ya que es más personalizado y permite una
                    multiplicidad de enfoques culturalmente sensibles. La
                    ampliación de escala es clave para un cambio sistémico
                    generalizado, pero cuando se realiza sin los recursos
                    adecuados para apoyar el poder popular que es tan clave para
                    el trabajo de justicia, y cuando se hace demasiado pronto,
                    antes de que un programa esté completamente en pie, puede
                    ser desastroso. Por lo tanto, hay valor en una multiplicidad
                    de enfoques personalizados, especialmente porque permiten el
                    pensamiento y los experimentos innovadores. Un esfuerzo
                    digno de mención aquí es Rescaled, un movimiento europeo que
                    impulsa los centros de detención a pequeña escala, que
                    afirman “habilita vías de reintegración a medida, permite un
                    enfoque más personal, menos burocracia, mejor seguridad
                    dinámica y brinda más oportunidades para que las personas
                    privadas de la libertad puedan asumir responsabilidad e
                    interactuar con la comunidad”. Particularmente cuando se
                    trata de prisiones y de reinserción, lo pequelo no solo no
                    es algo malo, es mejor porque permite un trabajo
                    personalizado.
                  </li>
                  <li>
                    <span className="strong">
                      ELIMINAR LO DEMASIADO CLÍNICO:{' '}
                    </span>
                    Las organizaciones fuertes evitan el lenguaje y la
                    interacción clínicos y de proveedor de servicios en favor de
                    la interacción humana normal y la construcción de relaciones
                    cercanas. Las casas de reinserción a pequeña escala de
                    Exodus (Países Bajos) son, por lo tanto, el hogar no de
                    “clientes” sino de “participantes”; involucran a muchos
                    voluntarios que se relacionan con sus participantes
                    simplemente como amigos y colegas, no como proveedores de
                    servicios, y no brindan sus servicios de salud mental en el
                    hogar porque “no somos una clínica forense”. Ruud Jacobs,
                    fundador de Small Scale Facility Amsterdam West, describió
                    el método de la instalación de la siguiente manera: “no un
                    método, sino un movimiento” y explicó que simplemente “estar
                    presente” y “normalizar todo” es muy útil, especialmente
                    para las y los jóvenes que han sido tratados de manera
                    clínica por tanto tiempo. Allí, los oficiales de seguridad
                    no son “oficiales” sino “anfitriones”, que también son
                    responsables de construir relaciones con las y los vecinos.
                  </li>
                  <li>
                    <span className="strong">EL PROCESO ES CRÍTICO: </span>
                    Construir desde abajo hacia arriba siempre es preferible a
                    construir de arriba hacia abajo. Las organizaciones deben
                    crear programas con las comunidades, no para ellas.
                    JustReinvest (Australia) creo vínculos con el pueblo y
                    especialmente con los mayores de Bourke al capacitarlos para
                    determinar dónde y cómo querían que se hiciera una inversión
                    comunitaria; no llegaron con otro programa listo para ser
                    adaptado a una comunidad cansada de tales imposiciones. El
                    Red Hook Community Justice Center siguió el lema de
                    “escuchar primero” cuando se convirtió en el segundo
                    tribunal comunitario de Nueva York, permitiendo que los
                    miembros de la comunidad determinen en qué debería centrarse
                    el tribunal, dónde debería ubicarse y cuáles eberían ser sus
                    procesos. Con ese fin, cada uno de los programas bajo el
                    título del Centro para la Innovación de la Corte, de los
                    cuales el Centro de Justicia Comunitaria Red Hook es uno, es
                    único, moldeado por las necesidades particulares de su
                    localidad y comunidad.
                  </li>
                  <li>
                    <span className="strong">MATENERSE ABIERTOS: </span>
                    Cuanto más abierta sea una instalación, cuanto más permita
                    licencias de fin de semana, licencias de trabajo, liceancia
                    educativa y más, más podrá promover la integración
                    comunitaria, lo cual es clave para los esfuerzos progresivos
                    de justicia. Las y los defensores y profesionales de todo el
                    mundo deberían considerar presionar para que haya muros más
                    porosos, permitiendo que las personas privadas de la
                    libertad participen en programas en la comunidad, en lugar
                    de construir más programas detrás de los muros.
                  </li>
                  <li>
                    <span className="strong">PRINCIPIOS ORIENTADORES: </span>
                    Entre los clientes de organizaciones globales, las mismas
                    palabras se repitieron en el contexto de describir programas
                    sólidos: confianza, empoderamiento, familia, respeto a largo
                    plazo, visión de cambio transformador, apoyo, ayuda,
                    amistad, motivación, poder, opciones, responsabilidad,
                    confianza, transformar. El lenguaje relacionado con
                    “sentirse humano” debido a un programa fuerte también fue
                    recurrente, como lo fue la mención de la comunidad y la
                    importancia de la dinámica de grupo. Las mismas palabras
                    también se repitieron como definitivas para su trabajo:
                    justicia, responsabilidad, innovación, respeto, dignidad,
                    comunidad (Figura 4).
                  </li>

                  <div>
                    <h3 style={{ textAlign: 'center' }}>Figura 4</h3>
                    <p style={{ textAlign: 'center' }}>
                      Adjetivos que describen los principios orientadores de la
                      organización
                      <br />
                      <span style={{ fontStyle: 'italic' }}>
                        Por favor nombre máximo cinco adjetivos que representan
                        los principios orientadores y los valores de su
                        organización.
                      </span>
                    </p>
                  </div>
                  <figure style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                      src="https://images.ctfassets.net/fhwlk5jsnns3/2QjBoGQA2EcVd8dzOCpWkV/2c72e8025545f2c7ba36dbc1048eaaf7/wordMap.png"
                      alt="Placeholder"
                      width="650"
                      height="650"
                    />
                  </figure>
                  <li>
                    <span className="strong">
                      CAMBIO INMEDIATO Y SISTÉMICO:{' '}
                    </span>
                    Los programas especialmente prometedores son aquellos que
                    son sistémicos e personalizados al mismo tiempo. Es decir,
                    pueden ofrecer servicios directos a las personas en el aquí
                    y ahora mientras siguen defendiendo y trabajando hacia un
                    cambio sistémico que finalmente pueda hacer que su trabajo
                    sea innecesario. Los programas hacen esto de varias maneras.
                    El Proyecto de Prisiones Africanas (Kenia, Uganda)
                    proporciona títulos y certificados en derecho, pero su
                    trabajo también ha resultado en la derogación de la pena de
                    muerte obligatoria en ambos países, gracias al trabajo de
                    defensa de sus estudiantes. Al incluir a los oficiales de la
                    prisión en sus clases junto con las y los estudiantes
                    privados de la libertad, también cambian fundamentalmente la
                    cultura del personal de la prisión, lo que puede conducir a
                    un cambio más sistémico. Juvenile Justice Advocates
                    International y Equis (México) participan principalmente en
                    el trabajo de políticas, pero también tienen proyectos
                    paralelos de Servicios Directo, lo que les permite no solo
                    ayudar a las y los clientes sino también involucrar a las y
                    los voluntarios y exponerlos a las cárceles y a las personas
                    privadas de la libertad, lo que contribuye al cambio
                    cultural. Genepi (Francia) contrata a estudiantes
                    universitarios de todo el país para organizar talleres
                    dentro de las cárceles, que brindan un servicio a las
                    personas privadas de la libertad, pero también sirven para
                    exponer a las y los jóvenes a una población carcelaria
                    humanizada e inculcarles una pasión por la justicia, otra
                    forma de cambio cultural de gran alcance. Curiosamente, solo
                    ocho de los programas de Inn Partner declararon que su
                    visión a largo plazo del cambio transformador era
                    “desmantelar las cárceles / prisiones”; “Reducir
                    drásticamente la población privada de la libertad” fue el
                    más frecuentemente citado (21 programas){' '}
                    <a
                      style={{ color: 'black' }}
                      href="https://assets.ctfassets.net/fhwlk5jsnns3/3gIp85pR7uHBwUW9K23TOb/735ece0f7899f5725ef86d0998c31fe2/Comparative_tables_of_questionnaire_responses_for_selected_programs.pdf"
                    >
                      (ver Cuadro 4)
                    </a>
                    .
                  </li>
                  <li>
                    <span className="strong">PODER POPULAR: </span>A pesar de
                    las “recetas” notables para un trabajo fuerte, es importante
                    tener en cuenta que los sistemas y las estructuras delinean
                    la justicia, pero las personas hacen que los sistemas cobren
                    vida. Por lo tanto, una persona puede tener un gran impacto
                    en todo un sistema de justicia: la INN lo ha visto desde
                    Costa Rica y la República Checa hasta Italia, los Países
                    Bajos y México, donde hay alternativas innovadoras a las
                    cárceles tradicionales (en los primeros cuatro países) y
                    cambios significativos en la detención preventiva (en
                    México) surgió porque un miembro de una agencia de justicia
                    estaba decidido a verlo suceder, a toda costa. La teoría
                    moderna de las redes sugiere el impacto del efecto en cadena
                    de personas y acciones individuales. Este concepto de “quién
                    y no qué funciona” también se aplica cuando consideramos los
                    ingredientes de los programas potentes. Una y otra vez, los
                    socios de la INN enfatizaron la importancia de contar con un
                    personal fuerte, especialmente —como en la instalación a
                    pequeña escala de Amsterdam West, donde seis de sus siete
                    empleados son marroquíes o surinameses—, personal que
                    proviene de entornos y comunidades culturales similares a
                    los de sus clientes.
                  </li>
                  <li>
                    <span className="strong">
                      CONSTRUIR LIDERAZGOS DIRECTAMENTE IMPACTADOS:{' '}
                    </span>
                    Invertir en un cambio sistémico también significa formar
                    líderes en los ámbitos del trabajo social y el activismo,
                    especialmente líderes culturalmente diversos e impactados
                    directamente, que han vivido en carne propia los problemas y
                    las soluciones y, por lo tanto, son un banco de sabiduría,
                    no como son vistos con demasiada frecuencia: una fuente de
                    problemas. Lograr que aquellos con experiencia vivida
                    orienten el trabajo programático es crítico, sin embargo, en
                    promedio, menos del 15% del personal en los programas de Inn
                    Partner eran personas privadas de la libertad /
                    anteriormente privadas de la libertad. En general, el 30% de
                    los programas de Inn Partner informaron que tenían al menos
                    un miembro del personal privado de la libertad o
                    anteriormente privado de la libertad y el 12% tenía miembros
                    de la junta privados de la libertad o anteriormente privados
                    de la libertad. Aunque muchos tienen familiares de personas
                    privadas de la libertad en el personal y/o personas más
                    afectadas como voluntarios, la INN desea que las
                    organizaciones avancen en la dirección de incluirlos como
                    miembros del personal y de la junta que cuentan con el apoyo
                    adecuado.
                    <br />
                    Para facilitar lo anterior, los gobiernos deben dejar de
                    crear barreras legislativas para, en palabras de
                    JustLeadership USA, que “las personas más cercanas al
                    problema” estén “más cerca a la solución”. Profesiones
                    completas: trabajo social en Sudáfrica y Canadá, el campo
                    legal en Kenia, por ejemplo, están esencialmente fuera del
                    alcance de las personas con convicciones, que tienen un gran
                    potencial para prestar un servicio tremendo a los campos y
                    sus comunidades. El litigio estratégico en este respecto es
                    necesario en muchos países.
                  </li>
                  <li>
                    <span className="strong">
                      AMPLIAR EL SIGNIFICADO DE “IMPACTAR DIRECTAMENTE”:{' '}
                    </span>
                    Cuando hablamos de las y los afectados por el sistema de
                    justicia penal, debemos ampliar nuestra definición: no solo
                    debemos considerar a las personas privadas de la libertad
                    sino también a sus familias, las personas que pertenecen a
                    comunidades excesivamente privadas de la libertad que logran
                    evitar verse implicadas en el sistema penal, y los barrios
                    de los que provienen, barrios que están sobrerrepresentados
                    en el sistema de justicia penal en general. Los servicios y
                    la atención a las familias de las personas privadas de la
                    libertad están aumentando en todo el mundo, desde toda
                    Europa hasta Singapur y los Estados Unidos, pero los
                    servicios para los vecindarios y las comunidades
                    sobrerrepresentados son escasos. Una excepción es el Red
                    Hook Community Justice Center (Estados Unidos), cuyos
                    servicios robustos (legales, educativos y culturales) están
                    disponibles para todas y todos los miembros de la comunidad
                    de Red Hook, Brooklyn, no solo para las personas que entran
                    en conflicto con la ley.
                  </li>

                  <li>
                    <span className="strong">HACER FRENTE AL TRAUMA: </span>
                    No se puede hacer trabajo de justicia en cualquier lugar sin
                    enfrentar el trauma. La población que está privada de la
                    libertad está envuelta en el trauma, tanto antes de la
                    prisión (como resultado de la desigualdad sistémica y el
                    racismo), como durante los años de deshumanización en la
                    prisión, y al salir para enfrentar la reinserción y la
                    estigmatización. “La esencia del trauma es la desconexión de
                    nosotros mismos”, dice el médico Gabor Mate; la prisión es
                    el mejor ejemplo de la desconexión, en muchos aspectos. Las
                    prácticas informadas por el trauma deben gobernar todas las
                    intervenciones. Debe ser un foco de la rehabilitación y los
                    programas de reinserción especialmente; las habilidades
                    vocacionales, la educación y el trabajo son claves, pero los
                    esfuerzos en esa dirección pueden ser rápidamente saboteados
                    cuando el trauma de una persona no se aborda y cura.
                  </li>
                  <li>
                    <span className="strong">INNOVACIÓN RADICAL: </span>
                    En términos generales, la justicia, un pilar fundamental de
                    cualquier sociedad estable, merece la libertad y,
                    especialmente, el apoyo financiero que se necesitan para
                    experimentar e innovar radicalmente. Si Silicon Valley puede
                    gastar libremente experimentando con aplicaciones y
                    tecnología hasta que descubra lo que funciona, ¿no debería
                    permitirse a las y los trabajadores de la justicia la
                    libertad y el respaldo para hacer lo mismo? Todas y todos
                    merecemos un mundo en el que se valore y nutra la
                    imaginación radical, para el bien común.
                  </li>
                </ul>
              </div>
            </div>
          </article>
        </div>
      </div>
    </>
  );
};

export default BlueprintSpTemplate;
