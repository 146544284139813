import React, { ReactNode } from 'react';
import SEO from '../Seo';
import WindowDimensionsProvider from '../../hooks/WindowDimensionsProvider';
import PageMenu from '../PageMenu/PageMenu';
import BlueprintHeader from '../BlueprintHeader/BlueprintHeader';
import { PageMenuItem } from '../../interfaces/page-menu-item.interface';
import ResponsiveLayout from '../ResponsiveLayout/ResponsiveLayout';

const BlueprintLayout = ({
  children,
  menuItems,
}: {
  children: ReactNode;
  menuItems?: PageMenuItem[];
}) => {
  return (
    <WindowDimensionsProvider>
      <SEO />
      <div
        style={{
          position: 'fixed',
          top: 0,
          width: '100%',
          margin: 0,
          zIndex: 5,
        }}
      >
        <BlueprintHeader />
        {menuItems && (
          <ResponsiveLayout
            breakpoint={1024}
            renderMobile={null}
            renderDesktop={<PageMenu menuItems={menuItems} offset={-175} />}
          />
        )}
      </div>
      <div id="root" className={'no-top-padding'}>
        <main id="content">{children}</main>
        <footer id="footer">
          <p>&copy; 2019 Incarceration Nations Network. All Rights Reserved.</p>
        </footer>
      </div>
    </WindowDimensionsProvider>
  );
};

export default BlueprintLayout;
