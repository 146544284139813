import React from 'react';

import NavItems from '../Nav/NavItems/NavItems';

import styles from '../Header/Header.module.scss';
import ResponsiveLayout from '../ResponsiveLayout/ResponsiveLayout';
import MobileBlueprintNav from '../Nav/MobileBlueprintNav/MobileBlueprintNav';
import { PageMenuItem } from '../../interfaces/page-menu-item.interface';
import { Link } from 'gatsby';

const BlueprintHeader = ({ menuItems }: { menuItems?: PageMenuItem[] }) => (
  <header className={styles.header}>
    <div className={styles.header_layout}>
      <Link to={'/'} className={styles.header_layout__logoLayout}>
        <div className={styles.header_layout__logoLayout__logo} />
      </Link>

      <ResponsiveLayout
        breakpoint={1024}
        renderMobile={<MobileBlueprintNav menuItems={menuItems} />}
        renderDesktop={<NavItems />}
      />
    </div>
  </header>
);

export default BlueprintHeader;
